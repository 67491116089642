import React, { useState, useEffect } from "react";
/* eslint-disable */
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
// nodejs library to set properties for components
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Navbar,
    NavItem,
    NavbarBrand,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";
import { Home, Users, Package, Tool, Save, FilePlus, Trash } from "react-feather";
import { HiMenuAlt2 } from "react-icons/hi";
import { FaRegChartBar } from "react-icons/fa";
import SidebarItems from "./SidebarItems";
const Sidebar = (props) => {
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    const toggleCollapse = () => {
        setCollapseOpen(!collapseOpen);
    };
    // closes the collapse
    const closeCollapse = () => {
        setCollapseOpen(false);
    };

    const [activeLink, setActiveLink] = useState([true, false, false, false, false, false]);

    const onSidebarItemChange = (index) => {
        let temp = activeLink;
        temp[index] = true;
        temp.forEach((el, i) => {
            if (index !== i) temp[i] = false;
        });
        setActiveLink([...temp]);
    };

    useEffect(() => {
        const temp = activeLink;
        temp.forEach((el, i) => {
            temp[i] = false;
        });
        const x = window.location.pathname.split("/")[2];
        if (x === "dashboard") {
            temp[0] = true;
            return setActiveLink([...temp]);
        } else if (x === "invoices") {
            temp[1] = true;
            return setActiveLink([...temp]);
        } else if (x === "challan") {
            temp[2] = true;
            return setActiveLink([...temp]);
        } else if (x === "products") {
            temp[5] = true;
            return setActiveLink([...temp]);
        } else if (x === "accounts") {
            temp[6] = true;
            return setActiveLink([...temp]);
        } else if (x === "customers") {
            temp[4] = true;
            return setActiveLink([...temp]);
        } else if (x === "sales") {
            temp[3] = true;
            return setActiveLink([...temp]);
        }
    }, [window.location.pathname]);

    return (
        <Navbar
            className={props.darkModeFlag === "false" ? "navbar-vertical fixed-left navbar-light" : `navbar-vertical fixed-left navbar-light bg-default`}
            style={{ zIndex: 1000000 }}
            expand="md"
            id="sidenav-main"
        >
            <Container fluid className="d-flex justify-content-between">
                {/* Toggler */}
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    <HiMenuAlt2 style={{ color: "white", fontSize: 30 }} />
                </button>

                {/* User */}
                <Nav className="align-items-center d-md-none">
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                            <Media className="align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img src={props.image} alt=".." />
                                </span>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">Welcome!</h6>
                                          </DropdownItem>
                                          <DropdownItem to="/admin/user-profile">
                                                <i className="ni ni-single-02" />
                                                <span>My profile</span>
                                          </DropdownItem>
                                          <DropdownItem to="/admin/index">
                                                <i className="ni ni-calendar-grid-58" />
                                                <span>Activity</span>
                                          </DropdownItem>
                                          <DropdownItem to="/admin/index">
                                                <i className="ni ni-support-16" />
                                                <span>Support</span>
                                          </DropdownItem>
                                          <DropdownItem divider /> */}
                            <DropdownItem href="#pablo">
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                {/* Collapse */}
                <Collapse navbar isOpen={collapseOpen} toggle={toggleCollapse}>
                    {/* Collapse header */}
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            <Col className="collapse-close" xs="7">
                                <button className="navbar-toggler" onClick={closeCollapse}>
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>
                    {/* Heading */}

                    <h6 className="navbar-heading text-muted geb" style={{ letterSpacing: "1.5px", fontSize: "14px" }}>
                        Features
                    </h6>

                    {/* Navigation */}
                    <Nav className="mb-md-3" navbar>
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[0]}
                            Icon={Home}
                            index={0}
                            onTap={onSidebarItemChange}
                            route="/admin/dashboard"
                            view={"Dashboard"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[1]}
                            Icon={Save}
                            index={1}
                            onTap={onSidebarItemChange}
                            route="/admin/invoices"
                            view={"Invoices"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[2]}
                            Icon={FilePlus}
                            index={2}
                            onTap={onSidebarItemChange}
                            route="/admin/challan"
                            view={"Challan"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[3]}
                            Icon={FaRegChartBar}
                            index={3}
                            onTap={onSidebarItemChange}
                            route="/admin/sales"
                            view={"Sales"}
                        />
                    </Nav>
                    {/* Divider */}
                    <hr className="my-3" />
                    {/* Heading */}
                    <h6 className="navbar-heading text-muted" style={{ letterSpacing: "1.5px", fontSize: "14px" }}>
                        More
                    </h6>

                    {/* Navigation */}
                    <Nav className="mb-md-3" navbar>
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[4]}
                            Icon={Users}
                            index={4}
                            onTap={onSidebarItemChange}
                            route="/admin/customers"
                            view={"Customers"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[5]}
                            Icon={Package}
                            onTap={onSidebarItemChange}
                            route="/admin/products"
                            index={5}
                            view={"Products"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[6]}
                            Icon={Tool}
                            index={6}
                            onTap={onSidebarItemChange}
                            route={"/admin/accounts"}
                            view={"Accounts"}
                        />
                        <SidebarItems
                            darkModeFlag={props.darkModeFlag}
                            active={activeLink[7]}
                            Icon={Trash}
                            index={7}
                            onTap={onSidebarItemChange}
                            route={"/admin/trash"}
                            view={"Bin"}
                        />
                    </Nav>
                    <hr className="my-3" />
                    {/* <h6 className="navbar-heading text-muted">Customisation</h6> */}

                    {/* <Row>
						<Col xl="3">
							<label className="custom-toggle">
								{props.darkModeFlag === "false" ? (
									<input type="checkbox" onChange={props.darkMode} />
								) : (
									<input
										type="checkbox"
										defaultChecked
										onChange={props.darkMode}
									/>
								)}
								<span className="custom-toggle-slider w-100 rounded-circle mr-3" />
							</label>
						</Col>
						<Col xl="9">
							<h4
								className={
									props.darkModeFlag === "false" ? "text-default" : "text-white"
								}
							>
								Dark Mode
							</h4>
						</Col>
					</Row> */}
                </Collapse>
            </Container>
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    uid: PropTypes.string,
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default Sidebar;
