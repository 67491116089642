import React from "react";
import { ModalBody, Col, Modal, ModalHeader, Row, FormGroup, Input, ModalFooter, Button } from "reactstrap";

const MontlyModal = ({ onCancelHandler, onDoneMonthlyListner, modal, onChangeDate, date }) => {
      return (
            <Modal isOpen={modal} toggle={onCancelHandler} style={{ zIndex: 10000000000 }}>
                  <ModalHeader className="bg " toggle={onCancelHandler}>
                        <span className="text fs-24 geb">Select Month</span>
                  </ModalHeader>
                  <ModalBody className="bg">
                        <Row>
                              <Col xl="12">
                                    <FormGroup>
                                          <label className="form-control-label" htmlFor="input-name">
                                                Date
                                          </label>
                                          <Input
                                                required
                                                className="form-control-alternative"
                                                placeholder="Name"
                                                type="date"
                                                name="date"
                                                value={date}
                                                onChange={onChangeDate}
                                          />
                                    </FormGroup>
                              </Col>
                        </Row>
                  </ModalBody>
                  <ModalFooter className="bg">
                        <Button color="primary" className="btn fira btn-success" onClick={onDoneMonthlyListner}>
                              Add
                        </Button>{" "}
                        <Button color="secondary" className="btn fira btn-warning" onClick={onCancelHandler}>
                              Cancel
                        </Button>
                  </ModalFooter>
            </Modal>
      );
};

export default MontlyModal;
