/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
import { LogOut, Users } from "react-feather";
import Axios from "axios";
const AdminNavbar = (props) => {
    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid className="d-flex align-items-center justify-content-between">
                    <span className="h4 mb-0 text-white geb text-uppercase d-none d-sm-inline-block" style={{ letterSpacing: "1px" }}>
                        {props.brandText}
                    </span>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="overflow-hidden" style={{ borderRadius: 12 }}>
                                        <img alt="..." style={{ maxWidth: 150 }} src={props.url} />
                                    </span>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem>
                                    <Users size="18" />
                                    <span className="mb-0 fs-14 fira ">{props.email}</span>
                                </DropdownItem>
                                <hr className="my-2" />
                                <DropdownItem
                                    onClick={async (e) => {
                                        const res = await Axios.post(
                                            `${process.env.REACT_APP_API_URL}/user/logout`,
                                            {},
                                            {
                                                headers: {
                                                    "SESSION-TOKEN": window.localStorage.getItem("session_token"),
                                                },
                                            }
                                        );
                                        if (res.data.code === 200) {
                                            window.localStorage.removeItem("session_token");
                                            window.localStorage.removeItem("uid");
                                            window.localStorage.removeItem("email");
                                            window.location.reload();
                                        } else {
                                            alert(res.data.message);
                                        }
                                    }}
                                >
                                    <LogOut size="18" />
                                    <span className="fira">Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
