import React, { useState } from "react";
/* eslint-disable */
import { Link } from "react-router-dom";
import { Button, Table, Row, Card, CardHeader, Col } from "reactstrap";
import { userBackend } from "../../../Views/UserProfile/user_backend";

import SheetTableItems from "./SheetTableItems";
import AddEntryByDate from "./AddEntryBySheet";
import { getDateForEntry } from "../../../Common/DateAndTime/getDate";
import { entryBackend } from "../../ClientEntry/clientEntry_backend";
import ModalDelete from "../../Client/component/ModalDelete";
const SheetComponentPage = ({ client, entry, setCustomers, date, customers, sid }) => {
	const initModals = {
		addModal: false,
		deleteModal: false,
		editModal: false,
	};

	const initState = {
		entry_id: "",
		date: getDateForEntry(date),
		description: "",
		client_id: "",
		material: "",
		rate: 0,
		qty: 1,
		length: 0,
		width: 0,
		amount: 0,
		cgst: 0,
		igst: 0,
		sgst: 0,
		total: 0,
		advance: 0,
		has_issued: false,
	};

	const [state, setState] = useState(initState);

	const [modals, setModals] = useState(initModals);

	const onAddEntryByDate = async () => {
		try {
			const formData = new FormData();
			formData.set("uid", window.localStorage.getItem("uid"));
			formData.set("client_id", client._id);
			formData.set("date", new Date(state.date));
			formData.set("material", state.material);
			formData.set("description", state.description);
			formData.set("item_length", state.length);
			formData.set("item_width", state.width);
			formData.set("amount", state.amount);
			formData.set("qty", state.qty);
			formData.set("rate", state.rate);
			formData.set("igst", state.igst);
			formData.set("cgst", state.cgst);
			formData.set("sgst", state.sgst);
			formData.set("total", state.total);
			formData.set("advance", state.advance);

			const res = await entryBackend.addNewEntry(formData, window.localStorage.getItem("session_token"));
			client.entries = [...client.entries, res.data];
			const temp = customers.findIndex((el) => el._id === client._id);
			customers.splice(temp, 1);
			customers.splice(temp, 0, client);
			setCustomers([...customers]);
			setModals({ ...modals, addModal: false });
		} catch (err) {
			console.log("err :", err);
			setModals({ ...modals, addModal: false });
		}
	};

	const onEditEntryByDate = async () => {
		try {
			const formData = new FormData();
			formData.set("uid", window.localStorage.getItem("uid"));
			formData.set("entry_id", state.entry_id);
			formData.set("client_id", client._id);
			formData.set("date", new Date(state.date));
			formData.set("material", state.material);
			formData.set("description", state.description);
			formData.set("item_length", state.length);
			formData.set("item_width", state.width);
			formData.set("amount", state.amount);
			formData.set("qty", state.qty);
			formData.set("rate", state.rate);
			formData.set("igst", state.igst);
			formData.set("cgst", state.cgst);
			formData.set("sgst", state.sgst);
			formData.set("total", state.total);
			formData.set("advance", state.advance);

			const res = await entryBackend.editEntry(formData, window.localStorage.getItem("session_token"));
			const index = client.entries.findIndex((el) => el._id === state.entry_id);
			client.entries.splice(index, 1);
			client.entries.splice(index, 0, res.data);
			const temp = customers.findIndex((el) => el._id === client._id);
			customers.splice(temp, 1);
			customers.splice(temp, 0, client);
			setCustomers([...customers]);
			setModals({ ...modals, editModal: false });
		} catch (err) {
			console.log("err :", err);
			setModals({ ...modals, editModal: false });
		}
	};

	const onCancelHandler = () => {
		setModals({ initModals });
		setState({ ...initState, date: getDateForEntry(date) });
	};

	const onDeleteHandler = async () => {
		try {
			const formData = new FormData();
			formData.set("date", state.date);
			formData.set("client_id", client._id);
			formData.set("entry_id", state.entry_id);
			await entryBackend.deleteEntry(formData, window.localStorage.getItem("session_token"));
			const index = client.entries.findIndex((el) => el._id === state.entry_id);
			client.entries.splice(index, 1);
			const temp = customers.findIndex((el) => el._id === client._id);
			customers.splice(temp, 1);
			customers.splice(temp, 0, client);
			setCustomers([...customers]);
			setModals({ ...modals, deleteModal: false });
		} catch (error) {
			setModals({ ...modals, deleteModal: false });
		}
	};

	const onTapActions = (id, mode) => {
		const index = entry.findIndex((el) => el._id === id);
		const elem = entry[index];
		console.log(elem);
		if (mode === "EDIT") {
			console.log("HERE");
			setState({
				...state,
				entry_id: id,
				date: getDateForEntry(elem.date),
				description: elem.description,
				client_id: elem.client_id._id,
				material: elem.material,
				rate: elem.rate,
				qty: elem.qty,
				length: elem.length,
				width: elem.width,
				amount: elem.amount,
				cgst: elem.cgst,
				igst: elem.igst,
				sgst: elem.sgst,
				total: elem.total,
				advance: elem.advance,
				has_issued: elem.has_issued,
			});
			setModals({ ...modals, editModal: true });
			return null;
		} else {
			setState({
				...state,
				client_id: elem.client_id,
				entry_id: id,
				date: elem.date,
			});
			setModals({ ...modals, deleteModal: true });
			return null;
		}
	};

	return (
		<>
			<Row className="mt-3">
				<Col>
					<Card className={"bg shadow"}>
						<CardHeader className="bg-transparent border-0">
							<Row>
								<Col xl="7">
									<h6 className="text-uppercase text-muted ls-1 mb-1">{client.clientName} </h6>
									<h2
										className={"text-default mb-0 geb fs-24"}
										style={{
											textTransform: "uppercase",
											letterSpacing: "1px",
										}}
									>
										{client.clientFirm}
									</h2>
									<h4 className={"text-default mb-0 geb "}></h4>
								</Col>
								<Row className="d-flex flex-grow-1">
									<Col className="my-auto d-flex">
										<Button
											className="btn btn-warning fira ml-auto"
											onClick={(e) => setModals({ ...modals, addModal: true })}
										>
											Add Entry
										</Button>
										<Button className="fira" color="danger">
											Download
										</Button>
										<Button className="fira" color="primary">
											Invoice
										</Button>
									</Col>
								</Row>
							</Row>
						</CardHeader>
						<Table className={"align-items-center table table-flush"} responsive>
							<thead className={"thead-light"}>
								<tr>
									<th scope="col">Status</th>
									<th scope="col">Product</th>
									<th scope="col">Description</th>
									<th scope="col">Qty</th>
									<th scope="col">Rate(&#8377;)</th>
									<th scope="col">SubTotal(&#8377;)</th>
									<th scope="col">CGST(&#8377;)</th>
									<th scope="col">SGST(&#8377;)</th>
									<th scope="col">Due(&#8377;)</th>
									<th scope="col">Advance(&#8377;)</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								{entry.map((elem, index) => (
									<SheetTableItems key={index} entry={elem} onTapActions={onTapActions} />
								))}
							</tbody>
						</Table>
					</Card>
				</Col>
			</Row>
			<AddEntryByDate
				modal={modals.addModal}
				onSubmitHandler={onAddEntryByDate}
				onCancelHandler={onCancelHandler}
				state={state}
				setState={setState}
				customer={client}
				heading={"ADD"}
			/>
			<AddEntryByDate
				modal={modals.editModal}
				onSubmitHandler={onEditEntryByDate}
				onCancelHandler={onCancelHandler}
				state={state}
				setState={setState}
				heading={"Edit"}
				customer={client}
			/>
			<ModalDelete onCancelHandler={onCancelHandler} onSubmitHandler={onDeleteHandler} modal={modals.deleteModal} />
		</>
	);
};

export default SheetComponentPage;
