import React from "react";
import LiteHeader from "../../../Common/Header/LiteHeader";
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
    Input,
	Button,
	Row,
	Col,
	Container,
	CardFooter,
} from "reactstrap";
const Index = ({darkModeFlag}) => {
    return (<><LiteHeader bg={"primary"} />
        <Container fluid className="mt--5">
				<Row>
					<Col className="order-xl-1 mt--7 " xl="4">
						<Card className="bg-secondary shadow-lg">
							<CardHeader className="bg-white border-0">
								<Row className="align-items-center geb">
									<div className="col">
										<h6 className="text-uppercase text-muted ls-1 mb-1">Add</h6>
										<h2
											className={
												darkModeFlag === "false"
													? "text-white mb-0 geb fs-24 "
													: "text-default mb-0 geb fs-24"
											}
											style={{
												textTransform: "uppercase",
												letterSpacing: "1px",
											}}
										>
											Challan
										</h2>
									</div>
									
								</Row>
							</CardHeader>
							<CardBody className="bg-white">
								{/* <div className="alert alert-success ">Successfully added</div> */}
								<h6 className="heading-small text-muted mb-2 geb ">
									Challan information
								</h6>
								<Form>
									<Row>
										<Col lg="12">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Date
												</label>
												<Input
													className="form-control-alternative nn"
													name="material_name"
													placeholder="Product"
													type="date"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg="12">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													From
												</label>
												<Input
													className="form-control-alternative nn"
													name="material_name"
													placeholder="Company"
													type="text"
												/>
											</FormGroup>
										</Col>
										
									</Row>
									<Row>
									<Col lg="12">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Description
												</label>
												<Input
													className="form-control-alternative nn"
													name="material_rate"
													placeholder="Rate"
													type="text"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Amount
												</label>
												<Input
													className="form-control-alternative nn"
													name="material_rate"
													placeholder="e.g 1000"
													type="Number"
												/>
											</FormGroup>
										</Col>
										<Col lg="6">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Quantity
												</label>
												<Input
													className="form-control-alternative nn"
													name="material_rate"
													placeholder="1"
													type="Number"
												/>
											</FormGroup>
										</Col>
									</Row>
									<div className="text-left ">
										<Button
											color="primary"
											className="fira shadow my-2"
										>
											Add
										</Button>
									</div>
								</Form>
							</CardBody>
							<CardFooter
								className={darkModeFlag === "false" ? "bg-default" : ""}
							>
								<div className="col-xl-9 text-primary text-left ml--3">
									<span className="fs-12 fira ">
										Note: To copy all products from a client to new will replace
										existing one
									</span>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
                </Container>
    </>);
}

export default Index;