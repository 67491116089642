import React, { useEffect, useCallback, useState } from "react";
import { trashBackend } from "../trash_backend";
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	Table,
	CardFooter,
	Badge,
} from "reactstrap";
import LiteHeader from "../../../Common/Header/LiteHeader";
import SetPassword from "./SetPassword";
import AskPassword from "./AskPassword";
import { getDate } from "../../../Common/DateAndTime/getDate";

const Trash = (props) => {
	const [modal, setModal] = useState({
		setPassword: false,
		askPassword: false,
	});

	const [password, setPassword] = useState({ value: "", error: false });
	const [loading, setLoading] = useState(false);
	const [entries, setEntries] = useState([]);

	const getTrash = useCallback(async () => {
		try {
			const formData = new FormData();
			formData.set("uid", window.localStorage.getItem("uid"));
			const res = await trashBackend.getAllEntry(formData);
			if (res.message === "Set a password first.") {
				return setModal({ ...modal, setPassword: true });
			} else {
				if (res.message === "Password Require.") {
					return setModal({ ...modal, askPassword: true });
				}
			}
		} catch (error) {
			console.log(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmitPassword = async () => {
		if (!password.value) {
			setPassword({ ...password, error: "It can't be empty." });
		}
		try {
			const formData = new FormData();
			formData.set("uid", window.localStorage.getItem("uid"));
			formData.set("password", password.value);
			const res = await trashBackend.getAllEntry(formData);
			setEntries(res.data);
			setLoading(true);
			onCancelHandler();
		} catch (error) {
			if (error.message === "Invalid password.") {
				return setPassword({ ...password, error: "Invalid password." });
			}
		}
	};

	const onCancelHandler = () => {
		setModal({
			setPassword: false,
			askPassword: false,
		});
	};

	useEffect(() => {
		getTrash();
	}, [getTrash]);

	return (
		<div>
			{loading ? <LiteHeader bg="primary" /> : false}
			{loading ? (
				<Container className="mt--9" fluid>
					<Row>
						<Col className="mb-5 mb-xl-0" xl="12">
							<Card className="bg-white shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center geb">
										<div className="col">
											<h6 className="text-uppercase text-muted ls-1 mb-1">
												Deleted
											</h6>
											<h2
												className={"text-default mb-0 geb fs-24"}
												style={{
													textTransform: "uppercase",
													letterSpacing: "1px",
												}}
											>
												Entries
											</h2>
										</div>
									</Row>
								</CardHeader>
								<Table className={"pp table table-flush"} responsive>
									<thead className={"thead-light"}>
										<tr>
											<th scope="col">Date</th>
											<th scope="col">Customer</th>
											<th scope="col">Product</th>
											<th scope="col">Description</th>
											<th scope="col">Qty</th>
											<th scope="col">Rate</th>
											<th scope="col">SubTotal(&#8377;)</th>
											<th scope="col">Due(&#8377;)</th>
											<th scope="col">Received(&#8377;)</th>
										</tr>
									</thead>
									<tbody>
										{entries.map((entry, index) => (
											<tr key={index} className="pp-l">
												<th scope="row">{getDate(entry.date)}</th>
												<th scope="row">{entry.client_id.clientFirm}</th>
												<th scope="row">
													{entry.material}{" "}
													{entry.has_issued ? (
														<Badge size="sm" color="success">
															<i className="fa fa-check mr-0"></i>
														</Badge>
													) : (
														<Badge size="sm" color="warning">
															pending
														</Badge>
													)}
												</th>
												<th scope="row">{entry.description}</th>
												<th scope="row">{entry.qty}</th>
												<th scope="row">{entry.rate}</th>
												<th scope="row">&#8377;{entry.amount}</th>
												<th scope="row">&#8377;{entry.total}</th>
												<th scope="row">
													&#8377;{entry.advance}{" "}
													{entry.total === 0 ? (
														<Badge size="sm" color="success">
															Paid
														</Badge>
													) : (
														<Badge size="sm" color="warning">
															received
														</Badge>
													)}
												</th>
											</tr>
										))}
									</tbody>
								</Table>
								<CardFooter className={""}>
									<div className="col text-warning text-left ml--3">
										<span className="fira bg" style={{ fontSize: "14px" }}>
											Info: Tap on Customer for entries
										</span>
									</div>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</Container>
			) : null}
			<SetPassword
				modal={modal.setPassword}
				onCancelHandler={onCancelHandler}
			/>
			<AskPassword
				modal={modal.askPassword}
				onCancelHandler={onCancelHandler}
				error={password.error}
				Done={onSubmitPassword}
				password={password.value}
				setPassword={setPassword}
			/>
		</div>
	);
};

export default Trash;
