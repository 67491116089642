import React, { useState } from "react";
import { ModalBody, Col, Modal, ModalHeader, Row, FormGroup, Input, ModalFooter, Button } from "reactstrap";

const BatchUpdateModal = ({ modal, onCancelHandler, onSubmitHandler }) => {
      const [amount, setAmount] = useState(0);

      return (
            <Modal isOpen={modal} toggle={onCancelHandler} style={{ zIndex: 10000000000 }}>
                  <ModalHeader className="bg " toggle={onCancelHandler}>
                        <span className="text fs-24 geb">Batch Receive</span>
                  </ModalHeader>
                  <ModalBody className="bg">
                        <Row>
                              <Col xl="12">
                                    <FormGroup>
                                          <label className="form-control-label" htmlFor="input-name">
                                                Amount
                                          </label>
                                          <Input
                                                required
                                                className="form-control-alternative"
                                                placeholder="0"
                                                type="number"
                                                value={amount}
                                                onChange={(evt) => {
                                                      setAmount(evt.target.value);
                                                }}
                                          />
                                    </FormGroup>
                              </Col>
                        </Row>
                  </ModalBody>
                  <ModalFooter className="bg">
                        <Button
                              color="primary"
                              className="btn fira btn-success"
                              onClick={(evt) => {
                                    onSubmitHandler(amount);
                              }}
                        >
                              Done
                        </Button>{" "}
                        <Button color="secondary" className="btn fira btn-warning" onClick={onCancelHandler}>
                              Cancel
                        </Button>
                  </ModalFooter>
            </Modal>
      );
};

export default BatchUpdateModal;
