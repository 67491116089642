import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#8f8f8f",
        borderBottomWidth: 0.3,
        alignItems: "center",
        height: 30,
        fontWeight: "bold",
    },
    description: {
        textAlign: "left",
        borderRightColor: "#8f8f8f",
        paddingLeft: 5,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    srno: {
        width: "5%",
        borderRightColor: "#8f8f8f",
        textAlign: "center",
        paddingRight: 8,
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    date: {
        width: "10%",
        borderRightColor: "#8f8f8f",
        textAlign: "left",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    qty: {
        width: "10%",
        borderRightColor: "#8f8f8f",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    rate: {
        width: "15%",
        borderRightColor: "#5f5f5f",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    amount: {
        paddingLeft: "5%",
        width: "25%",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
});

const InvoiceTableRow = ({ items }) => {
    const rows = items.map((item, index) => (
        <View key={index} style={styles.row}>
            <Text style={styles.date}>{index + 1}</Text>
            <Text style={{ width: "40%", display: "flex", flexWrap: "wrap" }}>
                <Text style={styles.description}>
                    {item.product} {item.description}
                </Text>
            </Text>
            <Text style={styles.qty}>{(Number(item.qty) * Number(item.length) * Number(item.width)).toFixed(2)}</Text>
            <Text style={styles.rate}>{item.rate}</Text>
            <Text style={styles.amount}>{(Number(item.qty) * Number(item.rate) * Number(item.length) * Number(item.width)).toFixed(2)}</Text>
        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
