import React, { useState, useEffect, useCallback } from "react";
/* eslint-disable */
import LiteHeader from "../../../Common/Header/LiteHeader";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    Container,
    Col,
    Row,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    CardFooter,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Badge,
} from "reactstrap";
import InvoicePagination from "./InvoicePagination";
import Main from "../Template/Main";
import { invoiceBackend } from "../invoice_backend";
import { AlertCircle, Check, Eye, Trash, TrendingUp, X } from "react-feather";
import ModalDelete from "../../Client/component/ModalDelete";
import ConfirmModal from "./ConfirmModal";
import { getDate, getDateForEntry } from "../../../Common/DateAndTime/getDate";
import { InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllGeneratedInvoices } from "../../../Redux/Actions/Grab";
import { RoundOff } from "../../../Common/DateAndTime/RoundOff";
import { FiMoreVertical } from "react-icons/fi";
import useInnerWidth from "../../../Common/DateAndTime/useInnerWidth";
const InvoiceIndex = ({ user }) => {
    console.log(user);
    const [state, setState] = useState({
        invoices: [],
        preview: { view: false },
        copyInvoices: [],
        invoice_id: "",
        client_id: "",
        receivedAmount: 0,
        hasDone: false,
    });
    const [modals, setModals] = useState({
        delete: false,
        receive: false,
    });
    const [copyInvoices, setCopyInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const getInvoices = useCallback(async () => {
        try {
            const formData = new FormData();
            formData.set("uid", window.localStorage.getItem("uid"));
            const res = await invoiceBackend.getInvoices(formData);
            const temp = [...res.data];
            temp.sort((a, b) => b.invoiceNumber - a.invoiceNumber);
            setCopyInvoices([...temp]);
            setState({
                ...state,
                invoices: temp,
                copyInvoices: temp,
            });
            setLoading(true);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const deleteHandler = async () => {
        try {
            const formData = new FormData();
            formData.set("invoice_id", state.invoice_id);
            const res = await invoiceBackend.deleteInvoice(formData);
            setModals({ ...modals, delete: false });
            const index = state.invoices.findIndex((el) => el._id === state.invoice_id);
            state.invoices.splice(index, 1);
            setState({ ...state, invoice_id: "" });
            setMoreMenu(-1);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getInvoices();
    }, [getInvoices]);

    const paidInvoice = async () => {
        try {
            const formData = new FormData();
            formData.set("invoice_id", state.invoice_id);
            formData.set("client_id", state.invoice_id);
            formData.set("receivedAmount", state.receivedAmount);
            await invoiceBackend.paidInvoice(formData);
            setModals({ ...modals, receive: false });
            const index = state.invoices.findIndex((el) => el._id === state.invoice_id);
            state.invoices[index].receivedAmount = parseFloat(state.invoices[index].receivedAmount) + parseFloat(state.receivedAmount);
            setState({ ...state, invoice_id: "" });
            setMoreMenu(-1);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (search.length > 0) {
            const searchResult = state.invoices.filter(
                (elem) => elem.clientFirm.toUpperCase().match(search.toUpperCase()) || elem.invoiceNumber.toString().match(search)
            );
            setState({ ...state, invoices: searchResult });
        } else {
            setState({ ...state, invoices: copyInvoices });
        }
    }, [search]);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const li = currentPage * entriesPerPage;
    const si = li - entriesPerPage;
    const splittedEntries = state.invoices.slice(si, li);
    const [moreMenu, setMoreMenu] = useState(-1);

    const innerW = useInnerWidth();

    const [responsive, setResponsive] = useState(innerW < 1280 ? true : false);

    useEffect(() => {
        if (innerW < 1280) setResponsive(true);
        else setResponsive(false);
    }, [innerW]);

    return (
        loading && (
            <>
                <LiteHeader bg="primary" />

                <Container className="mt--8" fluid>
                    <Row>
                        <Col className="d-flex flex-row-reverse"></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xl="12">
                            <Card className={" shadow"}>
                                <CardHeader className="bg-transparent">
                                    <Row>
                                        <Col xl="8">
                                            <h6 className="text-uppercase geb text-muted ls-1 mb-1">Issued</h6>
                                            <h2 className={"text-default geb fs-24 mb-0"}>Invoices</h2>
                                        </Col>
                                        <Col xl="4">
                                            <InputGroup className="nn form-control-alternative w-100">
                                                <Input
                                                    className="text-primary"
                                                    id="input-username"
                                                    placeholder="Company or Invoice Number"
                                                    type="text"
                                                    value={search}
                                                    onChange={(evt) => {
                                                        setSearch(evt.target.value);
                                                    }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className={"pp align-items-center table table-flush"} responsive={responsive}>
                                    <thead className={"thead-light"}>
                                        <tr>
                                            <th scope="col">Inv No</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Firm</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Amount without Tax</th>
                                            <th scope="row">Received</th>
                                            <th scope="row">Due Amount</th>

                                            <th scope="row">Preview</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {splittedEntries.map((el, idx) => (
                                            <tr>
                                                <th scope="row">{el.invoiceNumber}</th>
                                                <th scope="row">{getDateForEntry(el.date)}</th>
                                                <th scope="row">
                                                    <Link to={`/invoice/${el.client_id}`}>{el.clientName}</Link>
                                                </th>
                                                <th scope="row">{el.clientFirm}</th>
                                                <th scope="row">₹{RoundOff(el.total)}</th>
                                                <th scope="row">₹{RoundOff(el.notTaxAmount)}</th>
                                                <th scope="row">
                                                    ₹{RoundOff(el.receivedAmount)}
                                                    {RoundOff(el.receivedAmount) !== RoundOff(el.total) && el.receivedAmount !== undefined && (
                                                        <span className="badge badge-sm badge-danger ml-1">
                                                            <AlertCircle size="12" style={{ verticalAlign: "bottom" }} />
                                                        </span>
                                                    )}
                                                    {RoundOff(Number(el.receivedAmount) - Number(el.total)) === "0.00" && (
                                                        <Badge size="sm" color="success" className="ml-1">
                                                            <Check size="12" style={{ verticalAlign: "bottom" }} />
                                                        </Badge>
                                                    )}
                                                </th>
                                                <th scope="row">₹{RoundOff(Number(el.total) - Number(el.receivedAmount))}</th>
                                                <th scope="row">
                                                    <div class="dropdown">
                                                        <Button
                                                            size="sm"
                                                            class="btn btn-sm btn-icon-only "
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            onClick={() => setMoreMenu((prev) => (prev === idx ? -1 : idx))}
                                                            style={{ padding: 5 }}
                                                        >
                                                            <FiMoreVertical className="text-primary" style={{ fontSize: 18 }} />
                                                        </Button>
                                                        <div
                                                            class={
                                                                moreMenu === idx
                                                                    ? "dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                                                                    : "dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                                            }
                                                        >
                                                            <span
                                                                class={
                                                                    RoundOff(Number(el.total) - Number(el.receivedAmount)) === "0.00"
                                                                        ? "dropdown-item text-success"
                                                                        : "dropdown-item text-warning"
                                                                }
                                                                onClick={(evt) => {
                                                                    if (RoundOff(Number(el.total) - Number(el.receivedAmount)) === "0.00") return null;
                                                                    setModals((prev) => {
                                                                        return { ...prev, receive: true };
                                                                    });
                                                                    setState({
                                                                        ...state,
                                                                        invoice_id: el._id,
                                                                        client_id: el.client_id,
                                                                        hasDone: el.receivedAmount === el.total ? true : false,
                                                                    });
                                                                }}
                                                            >
                                                                {RoundOff(Number(el.total) - Number(el.receivedAmount)) === "0.00" ? "Closed" : "Close Invoice"}
                                                            </span>
                                                            <span
                                                                onClick={(evt) => {
                                                                    if (state.preview.view) {
                                                                        return setState({
                                                                            ...state,
                                                                            preview: {
                                                                                view: false,
                                                                            },
                                                                        });
                                                                    }
                                                                    setState({
                                                                        ...state,
                                                                        preview: {
                                                                            ...el,
                                                                            account: user.account,
                                                                            date: getDate(el.date),
                                                                            view: true,
                                                                        },
                                                                    });
                                                                    setMoreMenu(-1);
                                                                }}
                                                                class="dropdown-item"
                                                                href="#"
                                                            >
                                                                {state.preview.view && el._id === state.preview._id ? "Cancel" : "Preview"}
                                                            </span>
                                                            <span
                                                                class="dropdown-item"
                                                                onClick={() => {
                                                                    setModals({
                                                                        ...modals,
                                                                        delete: true,
                                                                    });
                                                                    setState({
                                                                        ...state,
                                                                        invoice_id: el._id,
                                                                    });
                                                                }}
                                                            >
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <Button size="sm" color="success">
                                                        <TrendingUp size="14" style={{ verticalAlign: "sub" }} />
                                                    </Button> */}
                                                    {/* 
                                                    <Button
                                                        size="sm"
                                                        color={state.preview.view && el._id === state.preview._id ? "warning" : "primary"}
                                                        onClick={(evt) => {
                                                            if (state.preview.view) {
                                                                return setState({
                                                                    ...state,
                                                                    preview: {
                                                                        view: false,
                                                                    },
                                                                });
                                                            }
                                                            setState({
                                                                ...state,
                                                                preview: {
                                                                    ...el,
                                                                    date: getDate(el.date),
                                                                    view: true,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        {state.preview.view && el._id === state.preview._id ? (
                                                            <X
                                                                size="14"
                                                                style={{
                                                                    verticalAlign: "sub",
                                                                }}
                                                            />
                                                        ) : (
                                                            <Eye
                                                                size="14"
                                                                style={{
                                                                    verticalAlign: "sub",
                                                                }}
                                                            />
                                                        )}
                                                    </Button>
                                                    <Button
                                                        className="ml-2"
                                                        size="sm"
                                                        color="danger"
                                                        onClick={() => {
                                                            setModals({
                                                                ...modals,
                                                                delete: true,
                                                            });
                                                            setState({
                                                                ...state,
                                                                invoice_id: el._id,
                                                            });
                                                        }}
                                                    >
                                                        <Trash size="14" style={{ verticalAlign: "sub" }} />
                                                    </Button> */}
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <CardFooter className="">
                                    <InvoicePagination
                                        invoices={state.invoices}
                                        invoicesPerPage={entriesPerPage}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                        <ModalDelete
                            modal={modals.delete}
                            onSubmitHandler={deleteHandler}
                            onCancelHandler={() => {
                                setModals({ ...modals, delete: false });
                            }}
                        />
                        {state.invoice_id && (
                            <ConfirmModal
                                modal={modals.receive}
                                onSubmitHandler={paidInvoice}
                                onCancelHandler={() => {
                                    setModals({ ...modals, receive: false });
                                    setState({ ...state, invoice_id: "", client_id: "" });
                                }}
                                onChangeAmount={(value) => {
                                    setState({ ...state, receivedAmount: value });
                                }}
                                state={state}
                                hasDone={state.hasDone}
                            />
                        )}
                    </Row>
                    <Row className="mt-4">
                        <Col xl="2" />
                        <Col xl="8">{state.preview.view && <Main invoice={state.preview} />}</Col>
                    </Row>
                </Container>
            </>
        )
    );
};

export default InvoiceIndex;
