import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const SheetPagination = ({ sheets, sheetsPerPage, setCurrentSheetPage, currentSheetPage }) => {
    let [pages, setPages] = useState([]);
    let temp = [];
    for (let page = 1; page <= Math.ceil(sheets.length / sheetsPerPage); page++) {
        if (page < 4) {
            temp.push(page);
        }
    }

    useEffect(() => {
        setPages(temp);
        if (Math.ceil(sheets.length / sheetsPerPage) > 3) {
            if (currentSheetPage === Math.ceil(sheets.length / sheetsPerPage)) {
                return setPages([currentSheetPage - 2, currentSheetPage - 1, currentSheetPage]);
            }
            if (currentSheetPage === 1) {
                return setPages([currentSheetPage, currentSheetPage + 1, currentSheetPage + 2]);
            }
            if (currentSheetPage > 2 && currentSheetPage <= Math.ceil(sheets.length / sheetsPerPage)) {
                return setPages([currentSheetPage - 1, currentSheetPage, currentSheetPage + 1]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSheetPage]);

    return (
        <nav aria-label="..." className="row">
            <div className="col-xl-2 d-flex geb align-items-center">{/* <div className="heading">{clients.length} Customers</div> */}</div>
            <Pagination className="col-xl-10 pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                <PaginationItem className={currentSheetPage === 1 ? "disabled" : ""}>
                    <PaginationLink onClick={(e) => setCurrentSheetPage(currentSheetPage - 1)}>
                        <ChevronLeft size="15" />
                        <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {pages.map((page, index) => (
                    <PaginationItem key={index} className={currentSheetPage === page ? "active" : ""} onClick={(e) => setCurrentSheetPage(page)}>
                        <PaginationLink>{page}</PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem className={currentSheetPage === pages[pages.length - 1] ? "disabled" : "text-primary"}>
                    <PaginationLink onClick={(e) => setCurrentSheetPage(currentSheetPage + 1)}>
                        <ChevronRight size="15" />
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </nav>
    );
};

export default SheetPagination;
