import axios from "axios";
const HEADER = {
    headers: {
        "SESSION-TOKEN": window.localStorage.getItem("session_token"),
    },
};
class InvoiceBackend {
    getInvoices(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/getAll`, formData, HEADER);
                //     const res = await axios.post(`http://localhost:5000/invoice/getAll`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    deleteInvoice(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/remove`, formData, HEADER);
                // const res = await axios.post(`http://localhost:5000/invoice/remove`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    paidInvoice(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/paid`, formData, HEADER);
                //     const res = await axios.post(`http://localhost:5000/invoice/paid`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getInvoiceReceives(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/paid`, formData, HEADER);
                //   const res = await axios.post(
                //     `http://localhost:5000/invoice/getReceived`,
                //     formData,
                //     HEADER
                //   );
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getClientInvoices(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/getClientInvoices`, formData, HEADER);
                //   const res = await axios.post(
                //     `http://localhost:5000/invoice/getClientInvoices`,
                //     formData,
                //     HEADER
                //   );
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    requestLedger(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/invoice/export/${data.cid}/${data.uid}`, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    downloadLedger(fname) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/invoice/download/${fname}`, {
                    headers: {
                        "SESSION-TOKEN": window.localStorage.getItem("session_token"),
                    },
                    responseType: "blob",
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fname); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                reject(error);
            }
        });
    }
}

export let invoiceBackend = new InvoiceBackend();
