import React, { useCallback, useEffect, useState } from "react";
import { Check, Menu } from "react-feather";
import { Row, Container, Col, Card, CardHeader, Table, Badge } from "reactstrap";
import { getDate } from "../../../Common/DateAndTime/getDate";
import Footer from "../../../Common/Footers/AdminFooter";
import { invoiceBackend } from "../invoice_backend";
import { RoundOff } from "../../../Common/DateAndTime/RoundOff";
import ReceivedHistory from "./RecivedHistory";
import { HiOutlineDownload } from "react-icons/hi";
import { FiMoreVertical } from "react-icons/fi";
import useCustomSnackbar from "../../../Common/Notification/CustomSnackbar";

const PreInvoiceComponents = ({ darkModeFlag }) => {
    const [state, setState] = useState({
        invoices: [],
        total: 0,
        received: 0,
        clientName: "",
        history: [],
        clientFirm: "",
        stopLoading: false,
        uid: localStorage.getItem("uid"),
        cid: window.location.pathname.split("/")[2],
    });

    const mSnackbar = useCustomSnackbar();

    const getAllInvoices = useCallback(async () => {
        try {
            const formData = new FormData();
            formData.set("uid", state.uid);
            formData.set("cid", state.cid);
            const res = await invoiceBackend.getClientInvoices(formData);
            const sorted = res.data.sort((a, b) => b.invoiceId - a.invoiceId);

            setState({
                ...state,
                invoices: sorted,
                clientName: res.clientName,
                clientFirm: res.clientFirm,
                total: RoundOff(res.totalDue),
                received: RoundOff(res.totalReceived),
                history: [...res.receivedHistory],
                stopLoading: true,
            });
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, [state.cid]);

    useEffect(() => {
        getAllInvoices();
        return () => {};
    }, [getAllInvoices]);

    const [open, setOpen] = useState({
        sidebar: false,
        receiveAmount: 0,
    });

    const onDeleteBtnHandler = () => {};

    const [headMenu, setHeadMenu] = useState(false);

    const onDownloadHandler = async () => {
        try {
            const data = {
                uid: state.uid,
                cid: state.cid,
            };
            const res = await invoiceBackend.requestLedger(data);
            mSnackbar({ variant: true, message: "Wait until your file downloads!", head: "Successfully Exported!" });
            setTimeout(async () => {
                await invoiceBackend.downloadLedger(res.message.split("/")[2]);
            }, 1500);
        } catch (error) {
            return mSnackbar({ variant: false, message: error.message, head: "Something Went Wrong!" });
        }
    };

    return (
        state.stopLoading && (
            <div style={{ minHeight: "100vh" }}>
                {open.sidebar && (
                    <ReceivedHistory historyArr={state.history} setOpen={setOpen} cname={state.clientName} onDeleteBtnHandler={onDeleteBtnHandler} />
                )}
                <Container fluid className={"bg-gradient-primary"} style={{ paddingTop: "20px" }}>
                    <Row>
                        <Col xl="12" className="d-flex ai-center">
                            <span
                                onClick={(e) =>
                                    setOpen((prev) => {
                                        return { ...prev, sidebar: true };
                                    })
                                }
                                className="text-white"
                            >
                                <Menu className="cp" size="32" />
                            </span>
                            <span className="text-white geb fs-24" style={{ marginLeft: "10px" }}>
                                Total Due : &#8377;{state.total} Received:&#8377;
                                {state.received}
                            </span>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Card className={"bg shadow"}>
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
                                    <div>
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">{state.clientName}</h6>
                                        <h2
                                            className={"text-default mb-0 geb fs-24"}
                                            style={{
                                                textTransform: "uppercase",
                                                letterSpacing: "1px",
                                            }}
                                        >
                                            {state.clientFirm}
                                        </h2>
                                    </div>
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-primary btn-icon-only"
                                            onClick={() => {
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FiMoreVertical className="text-white" style={{ fontSize: 18 }} />
                                        </button>
                                        <ul
                                            class={
                                                headMenu
                                                    ? "dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                                                    : "dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                            }
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li>
                                                <span onClick={onDownloadHandler} class="dropdown-item ">
                                                    <HiOutlineDownload style={{ verticalAlign: "bottom" }} />
                                                    Download Ledger
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </CardHeader>
                                <Table className={"align-items-center table table-flush"} responsive>
                                    <thead className={"thead-light"}>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Invoice No.</th>
                                            <th scope="col">Taxed Amount</th>
                                            <th scope="col">NonTaxed Amount</th>
                                            <th scope="col">Received</th>
                                            <th scope="col">Due(&#8377;)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.invoices.map((el, index) => (
                                            <tr className="pp-l" key={index}>
                                                <th scope="row">{getDate(el.date)}</th>
                                                <th scope="row">{el.invoiceId}</th>
                                                <th scope="row">&#8377;{RoundOff(el.taxedValue)}</th>
                                                <th scope="row">&#8377;{RoundOff(el.nonTaxedValue)}</th>
                                                <th scope="row">&#8377;{RoundOff(el.entryReceived)} </th>
                                                <th scope="row">
                                                    &#8377;{RoundOff(el.due)}
                                                    {el.due !== 0 ? (
                                                        <Badge size="sm" color="warning">
                                                            pending
                                                        </Badge>
                                                    ) : (
                                                        <Badge size="sm" color="success">
                                                            <Check size="12" />
                                                        </Badge>
                                                    )}
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className={"bg-gradient-primary"} fluid>
                    <Footer darkModeFlag={"true"} />
                </Container>
            </div>
        )
    );
};

export default PreInvoiceComponents;
