import React, { useState, useEffect, useCallback } from "react";
/* eslint-disable */
import LiteHeader from "../../../Common/Header/LiteHeader";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    Container,
    Col,
    Row,
    Form,
    FormGroup,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    CardFooter,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { InputGroup } from "reactstrap";
import useInnerWidth from "../../../Common/DateAndTime/useInnerWidth";
import { sales } from "../sales_backend";
import DropdownList from "../../../global/DropdownList";
const SalesIndex = ({ user }) => {
    const [state, setState] = useState({
        clients: [],
        fetched: {},
        fetchedData: false,
    });

    const [selected, setSelected] = useState({
        client_id: null,
        date: null,
        flag: "UNPAID",
    });

    const [loading, setLoading] = useState(false);
    const getClients = useCallback(async () => {
        try {
            const res = await sales.getAllClients();
            console.log(res);
            setState({
                clients: res.data,
            });
            setLoading(true);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getClients();
    }, [getClients]);

    const innerW = useInnerWidth();

    const [responsive, setResponsive] = useState(innerW < 1280 ? true : false);

    useEffect(() => {
        if (innerW < 1280) setResponsive(true);
        else setResponsive(false);
    }, [innerW]);

    const onCustomerChange = (id) => {
        setSelected({ ...selected, client_id: id });
    };

    const onSubmitHandler = async () => {
        setState({ ...state, fetched: {}, fetchedData: false });
        try {
            const formData = new FormData();
            formData.set("date", selected.date);
            formData.set("flag", selected.flag);
            if (selected.client_id) formData.set("cid", selected.client_id);
            const res = await sales.getData(formData);
            setState({ ...state, fetched: res.data, fetchedData: true });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        loading && (
            <>
                <LiteHeader bg="primary" />
                <Container fluid>
                    <Row>
                        <Col className="order-xl-1 mt--8 " xl="4">
                            <Card className="bg-secondary shadow-lg">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center geb">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">Get</h6>
                                            <h2
                                                className={"text-default mb-0 geb fs-24"}
                                                style={{
                                                    textTransform: "uppercase",
                                                    letterSpacing: "1px",
                                                }}
                                            >
                                                Sales
                                            </h2>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody className="bg-white">
                                    {/* <div className="alert alert-success ">Successfully added</div> */}
                                    <h6 className="heading-small text-danger text-muted mb-2 geb ">Required</h6>
                                    <Form>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label pp fs-12">Date</label>
                                                    <Input
                                                        className="form-control-alternative nn"
                                                        name="material_name"
                                                        placeholder="Product"
                                                        type="date"
                                                        value={selected.date}
                                                        onChange={(evt) => {
                                                            setSelected({ ...selected, date: evt.target.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <div className="d-flex">
                                                    <span
                                                        className={selected.flag === "UNPAID" ? "text-primary fs-12 mt-1 mr-2" : "text-muted fs-12 mt-1 mr-2"}
                                                    >
                                                        Unpaid
                                                    </span>
                                                    <label className="custom-toggle">
                                                        <input
                                                            onClick={() => {
                                                                console.log("clicked");
                                                                setSelected((prev) => ({ ...prev, flag: prev.flag === "UNPAID" ? "PAID" : "UNPAID" }));
                                                            }}
                                                            defaultChecked={selected.flag === "UNPAID" ? false : true}
                                                            type="checkbox"
                                                        />
                                                        <span className="custom-toggle-slider rounded-circle" />
                                                    </label>
                                                    <span className={selected.flag === "PAID" ? "text-primary fs-12 mt-1 ml-2" : "text-muted fs-12 mt-1 ml-2"}>
                                                        Paid
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <h6 className="heading-small text-danger text-muted mb-2 geb ">Optional</h6>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label pp fs-12">Customers</label>
                                                    <DropdownList
                                                        className="form-control-alternative"
                                                        clients={state.clients}
                                                        placeholder="Client-Names"
                                                        type="text"
                                                        cid={selected.client_id}
                                                        onChange={onCustomerChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="text-left ">
                                            <Button onClick={onSubmitHandler} color="primary" className="fira shadow my-2">
                                                Get
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                                <CardFooter className={""}>
                                    <div className="col-xl-9 text-primary text-left ml--3">
                                        <span className="fs-12 fira ">
                                            Note: Selection of Client is optional. But, It'll fetch data for respective customer.
                                        </span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        {state.fetchedData && (
                            <Col className="order-xl-1 mt--8 mx-auto" xl="8" responsive>
                                <Card className={"shadow w-100"}>
                                    <CardHeader className="bg-transparent">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h6 className="text-uppercase text-muted ls-1 mb-1">{state.fetched.requestedDate}</h6>
                                                <h2
                                                    className={"text-default mb-0 geb fs-24"}
                                                    style={{
                                                        textTransform: "uppercase",
                                                        letterSpacing: "1px",
                                                    }}
                                                >
                                                    {state.fetched.customer ? `${state.fetched.customer.clientFirm}'s Sales` : "Sales"}
                                                </h2>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <Table className={"align-items-center table table-flush"} responsive>
                                        <thead className={"  pp thead-light"}>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Total Sq.ft</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(state.fetched.stats).map((el, index) => (
                                                <tr key={index} className="pp-l">
                                                    <th scope="row" className={el === "Total" ? "text-danger" : "text-primary"}>
                                                        {index + 1}
                                                    </th>
                                                    <th scope="row" className={el === "Total" ? "text-danger" : "text-primary"}>
                                                        {el}
                                                    </th>
                                                    <th scope="row" className={el === "Total" ? "text-danger" : "text-primary"}>
                                                        {state.fetched.stats[el]}
                                                    </th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className={""}></CardFooter>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </>
        )
    );
};

export default SalesIndex;
