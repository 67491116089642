import React from "react";
import {
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Container,
	Button,
	Modal,
	ModalHeader,
	ModalFooter,
} from "reactstrap";

const EditClientModal = ({
	modal,
	onCancelHandler,
	state,
	onChangeValue,
	handleSubmit,
}) => {
	return (
		<Modal isOpen={modal} size="lg" toggle={onCancelHandler}>
			<ModalHeader className="bg" toggle={onCancelHandler}>
				<span className="text fs-24 geb">Edit</span>
			</ModalHeader>
			<Container fluid>
				<Col>
					<Form>
						{state.status ? (
							<div className="alert alert-success">Successfully Added</div>
						) : (
							<></>
						)}
						{state.error ? (
							<div className="alert alert-danger">{state.error}</div>
						) : (
							<></>
						)}
						<h6 className="heading-small text-muted mb-4  geb">
							Personal information
						</h6>
						<Row>
							<Col lg="6">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-name"
									>
										Name
									</label>
									<Input
										className="nn form-control-alternative"
										value={state.clientName}
										name="clientName"
										placeholder="Name"
										onChange={onChangeValue}
										type="text"
									/>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-email"
									>
										Contact Number
									</label>
									<Input
										className="nn form-control-alternative"
										id="input-email"
										placeholder="Phonenumber"
										type="number"
										name="clientNumber"
										onChange={onChangeValue}
										value={state.clientNumber}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col lg="12">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-first-name"
									>
										Address
									</label>
									<Input
										className="nn form-control-alternative"
										id="input-first-name"
										placeholder="Address"
										type="text"
										name="clientAddress"
										onChange={onChangeValue}
										value={state.clientAddress}
									/>
								</FormGroup>
							</Col>
						</Row>
						<hr className="my-4" />
						{/* Address */}
						<h6 className="heading-small text-muted mb-4 geb ">
							Firm information
						</h6>
						<Row>
							<Col md="12">
								<FormGroup>
									<label
										className="form-control-label  pp fs-12"
										htmlFor="input-address"
									>
										Name
									</label>
									<Input
										className="nn form-control-alternative"
										id="input-address"
										placeholder="Firm Name"
										type="text"
										name="clientFirm"
										onChange={onChangeValue}
										value={state.clientFirm}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col lg="12">
								<FormGroup>
									<label
										className="form-control-label  pp fs-12"
										htmlFor="input-city"
									>
										GST Number
									</label>
									<Input
										className="nn form-control-alternative"
										id="input-city"
										placeholder="GST Number"
										type="text"
										name="clientGST"
										onChange={onChangeValue}
										value={state.clientGST}
									/>
								</FormGroup>
							</Col>
						</Row>
						<div className="text-right d-flex mb-3  mt-2"></div>
					</Form>
				</Col>
			</Container>
			<ModalFooter className="bg">
				<Button className="fira" onClick={handleSubmit} color="primary">
					Save
				</Button>
				<Button color="danger" className="fira" onClick={onCancelHandler}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EditClientModal;
