const getDate = (date) => {
	let dd = new Date(date).getDate();
	let mm = new Date(date).getMonth() + 1;
	const yy = new Date(date).getFullYear();

	mm = mm < 10 ? "0" + mm : mm;
	dd = dd < 10 ? "0" + dd : dd;
	return `${dd}-${mm}-${yy}`;
};

const getDateForEntry = (date) => {
	let dd = new Date(date).getDate();
	let mm = new Date(date).getMonth() + 1;
	const yy = new Date(date).getFullYear();

	mm = mm < 10 ? "0" + mm : mm;
	dd = dd < 10 ? "0" + dd : dd;
	return `${yy}-${mm}-${dd}`;
};

module.exports = {
	getDate,
	getDateForEntry,
};
