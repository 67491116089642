import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { RoundOffWithAmount } from "../../../Common/DateAndTime/RoundOff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#8f8f8f",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        fontSize: 9,
        fontWeight: "bold",
        marginRight: 18,
    },
    description: {
        width: "85%",
        textAlign: "right",
        borderRightColor: "#8f8f8f",
        borderRightWidth: 0.4,
        paddingRight: 8,
    },
    total: {
        width: "15%",
        textAlign: "right",
        paddingRight: 8,
        fontFamily: "OSB",
    },
    rowx: {
        marginRight: 18,
        flexDirection: "row",
        alignItems: "center",
        height: 24,
        fontSize: 8,
        fontWeight: "bold",
    },
    descriptionx: {
        width: "85%",
        textAlign: "right",
        paddingRight: 8,
        fontFamily: "OSB",
    },
    totalx: {
        width: "15%",
        textAlign: "right",
        paddingRight: 8,
        fontFamily: "OSM",
    },
});

const InvoiceTableFooter = ({ items }) => {
    const total = items
        .map((item) => item.qty * item.rate * Number(item.length) * Number(item.width))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const cgst = total * 0.09;
    const totalx = total * (1 + 0.18);
    return (
        <View>
            <View style={styles.rowx}>
                <Text style={styles.descriptionx}>SUBTOTAL</Text>
                <Text style={styles.totalx}>{Number.parseFloat(total).toFixed(2)}</Text>
            </View>
            <View style={styles.rowx}>
                <Text style={styles.descriptionx}>CGST @{9}%</Text>
                <Text style={styles.totalx}>{Number.parseFloat(cgst).toFixed(2)}</Text>
            </View>
            <View style={styles.rowx}>
                <Text style={styles.descriptionx}>SGST @{9}%</Text>
                <Text style={styles.totalx}>{Number.parseFloat(cgst).toFixed(2)}</Text>
            </View>
            <View style={styles.rowx}>
                <Text style={styles.descriptionx}>Round Off</Text>
                <Text style={styles.totalx}>{RoundOffWithAmount(totalx)[1]}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.descriptionx}>TOTAL</Text>
                <Text style={styles.total}>{RoundOffWithAmount(totalx)[0]}</Text>
            </View>
        </View>
    );
};

export default InvoiceTableFooter;
