import axios from "axios";

class AuthBackend {
	loginWithEmailAndPassword(formData) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/user/login`,
					formData
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}
	registerWithEmailAndPassword(formData) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/user/register`,
					formData
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}
}
export let authBackend = new AuthBackend();
