import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
	ModalBody,
	Col,
	Modal,
	ModalHeader,
	Row,
	ModalFooter,
	Button,
	FormGroup,
	Input
} from "reactstrap";
import { getDate } from "../../../Common/DateAndTime/getDate";
import { invoiceBackend } from "../invoice_backend";

const ConfirmModal = ({ onCancelHandler, onSubmitHandler, modal,state,onChangeAmount,hasDone }) => {

	const [batchHistory,setBatchHistory] = useState({
		history:[],
		stopLoading:false
	})

	const getHistory = useCallback(async ()=>{
		try {
			const formData = new FormData();
			formData.set("uid",localStorage.getItem("uid"));
			formData.set("invoice_id",state.invoice_id)
			const res = await invoiceBackend.getInvoiceReceives(formData);
			setBatchHistory({...state,history:[...res.data],stopLoading:true});
		} catch (error) {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(()=>{
		getHistory();
	},[getHistory])

	return (
		<Modal
			isOpen={modal}
			toggle={onCancelHandler}
			style={{ zIndex: 10000000000 }}
		>
			<ModalHeader className="bg " toggle={onCancelHandler}>
				<h3 className="text fs-24 geb">Paid</h3>
			</ModalHeader>
			<ModalBody className="bg">
				{!hasDone  && <Row>
					<Col xl="12">
						<FormGroup>
							<label className="form-control-label  pp fs-12">Amount</label>
							<Input
								className="form-control-alternative nn"
								placeholder="0"
								type="number"
								value={state.receivedAmount}
								onChange={(e)=>{onChangeAmount(e.target.value)}}
							/>
						</FormGroup>
					</Col>
				</Row>}
				{batchHistory.stopLoading && (<Row>
					<Col xl="12">
					<FormGroup>
							<label className="form-control-label  pp fs-12">Previous Received</label>
							<table className="table table-flush rounded">
								<thead className="thead-light pp">
									<th scope="col">#</th>
									<th scope="col">Date</th>
									<th scope="col">Amount</th>
								</thead>
								<tbody>
										{batchHistory.history.map((el,index)=> {return <tr key={index}  className="pp">
											<th scope="row">{index+1}</th>
											<th scope="row">{getDate(el.date)}</th>
											<th scope="row">{el.amount}</th>
										</tr>})}
								</tbody>
							</table>
						</FormGroup>
					</Col>
				</Row>)}
			</ModalBody>
			<ModalFooter className="bg">
				{!hasDone && <Button
					color="primary"
					className="btn fira btn-success"
					onClick={onSubmitHandler}
				>
					Paid
				</Button>}{" "}
				<Button
					color="secondary"
					className="btn fira btn-warning"
					onClick={onCancelHandler}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ConfirmModal;
