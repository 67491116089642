export const RoundOff = (amt) => {
    return (Math.round(amt * 100) / 100).toFixed(2);
};

export const RoundOffWithAmount = (amt) => {
    const rounded = (Math.round(amt * 100) / 100).toFixed(2);
    const decimal = rounded.split(".")[1];
    if (parseInt(decimal) > 50) {
        return [`${parseInt(rounded.split(".")[0]) + 1}.00`, parseInt("0." + decimal)];
    }
    return [`${parseInt(rounded.split(".")[0])}.00`, "-0." + parseInt(decimal)];
};
