import React, { useState } from "react";
import ListItem from "./ListItem";
import Main from "../../Invoice/Template/Main";
import { Row, Col, Card, CardHeader, Table, Button, CardFooter } from "reactstrap";
import ClientPagination from "./ClientPagination";
import { Filter } from "react-feather";
import MontlyModal from "./MothlyModal";
import { getDateForEntry } from "../../../Common/DateAndTime/getDate";
import GenrateInvoiceNumber from "../../Invoice/Component/GenerateInvoiceModal";
import { clientsBackend } from "../client_backend";
import ConfirmModal from "./ConfirmModal";
import BatchUpdateModal from "../../Invoice/Component/BatchUpdateModal";
import { FiMoreVertical } from "react-icons/fi";
import { HiOutlineDownload } from "react-icons/hi";

const ClientComponentPage = (props) => {
    let { entries, user, customer, onAddEntryHandler, onTapEdit, onTapDeleteEntry, onTapDownload, setCustomer, setOpen } = props;

    // backup for returning to original state
    const [copyOfEntries] = useState([...entries]);
    const [modals, setModals] = useState({
        invoice: false,
        confirmSave: false,
        batchUpdate: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage] = useState(50);
    const [dataForInvoice, setDataForInvoice] = useState({ ready: false });

    // li - Last Index a page
    // si - Start Index of a page
    // Splitting rows by them
    const li = currentPage * entriesPerPage;
    const si = li - entriesPerPage;
    const splittedEntries = entries.slice(si, li);

    // flag for switch on selecting and non-selecting, checkall for selecting all rows
    const [filterSwitch, setFilterSwitch] = useState({ flag: false, checkedAll: false });

    const selectAllEntries = (evt) => {
        // if checkAll if true then it will deselect all the rows
        if (filterSwitch.checkedAll) {
            const checkedEntries = entries.map((el) => {
                return { ...el, checked: false };
            });
            setCustomer({ ...customer, entries: checkedEntries });
            return setFilterSwitch({ ...filterSwitch, checkedAll: false });
        }
        // select All the entries
        const checkedEntries = entries.map((el) => {
            return { ...el, checked: true };
        });
        setCustomer({ ...customer, entries: checkedEntries });
        setFilterSwitch({ ...filterSwitch, checkedAll: true });
    };

    const onSelectEntryListner = (id) => {
        //using id finding index opf entry and changing its flag to true or vice-versa
        const entryIndex = entries.findIndex((el) => el._id === id);
        entries[entryIndex].checked = !entries[entryIndex].checked;
        setCustomer({ ...customer, entries: entries });
    };

    const onDoneEntriesSelection = (date, invNo) => {
        setModals({ ...modals, invoice: false });
        const checkedEntries = entries.filter((el) => el.checked === true);
        const preparedData = {
            ...dataForInvoice,
            date: date,
            invoiceNumber: invNo,
            account: user.account,
            bank_name: user.bank_name,
            email: user.email,
            address: user.address,
            firm: user.firm,
            gst: user.gst,
            ifsc: user.ifsc,
            url: user.url,
            name: user.name,
            phone: user.phone,
            entries: checkedEntries,
            client_id: customer._id,
            uid: customer.uid,
            clientAddress: customer.clientAddress,
            clientFirm: customer.clientFirm,
            clientGST: customer.clientGST,
            clientName: customer.clientName,
            clientPhone: customer.clientPhone,
            ready: true,
            view: true,
        };
        setDataForInvoice(preparedData);
        console.log(preparedData);
    };

    // SAVE INVOICE
    const onSaveInvoice = async (evt) => {
        try {
            const formData = new FormData();
            dataForInvoice.entries.map((elem, index) => formData.set(`entry_ids[${index}]`, elem._id));
            formData.set("uid", dataForInvoice.uid);
            formData.set("date", new Date(dataForInvoice.date));
            formData.set("client_id", dataForInvoice.client_id);
            formData.set("invNo", dataForInvoice.invoiceNumber);
            console.log(dataForInvoice.uid, new Date(dataForInvoice.date, dataForInvoice.client_id, dataForInvoice.invoiceNumber));
            const res = await clientsBackend.saveInvoice(formData);
            const copy = [...customer.entries];
            copy.forEach((entry) => {
                for (let i = 0; i < dataForInvoice.entries.length; i++) {
                    const elem = dataForInvoice.entries[i];
                    if (elem._id === entry._id) {
                        entry.has_issued = true;
                        entry.issued = res.data;
                        entry.checked = false;
                    }
                }
            });
            setCustomer({ ...customer, entries: copy });
            window.localStorage.setItem("inv_number", Number(dataForInvoice.invoiceNumber) + Number(1));
            setModals({ ...modals, confirmSave: false });
            setDataForInvoice({ ...dataForInvoice, ready: false });
        } catch (err) {
            console.log(err);
        }
    };

    // sorting entries monthly
    const switchingMonthly = () => {
        // if monthly already turned on
        if (monthly.switch) {
            setMonthly({ ...monthly, switch: false });
            setCustomer({ ...customer, entries: copyOfEntries });
            return null;
        }
        setMonthly({ ...monthly, switch: true, modal: true });
    };

    // date for conditioning, switch for returning back to original state
    const [monthly, setMonthly] = useState({
        date: getDateForEntry(new Date()),
        modal: false,
        switch: false,
    });

    // to get month from date (yyyy-mm-dd)
    const getMonthOutOfDate = (date) => {
        const arr = date.split("-");
        return arr[1];
    };

    // getting entries sorted
    const onDoneMonthlyListner = (evt) => {
        const sortedEntries = copyOfEntries.filter((el) => getMonthOutOfDate(getDateForEntry(el.date)) === getMonthOutOfDate(monthly.date));
        setCustomer({ ...customer, entries: sortedEntries });
        setMonthly({ ...monthly, modal: false, switch: true });
    };

    const onBatchUpdate = async (amount) => {
        try {
            const formData = new FormData();
            formData.set("uid", window.localStorage.getItem("uid"));
            formData.set("client_id", customer._id);
            formData.set("amount", amount);
            await clientsBackend.batchUpdate(formData);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const [moreMenu, setMoreMenu] = useState(-1);

    const [headMenu, setHeadMenu] = useState(false);

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card className={props.darkModeFlag === "false" ? "bg-default shadow" : "bg shadow"}>
                        <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
                            <div>
                                <h6 className="text-uppercase text-muted ls-1 mb-1">{customer.clientName}</h6>
                                <h2
                                    className={"text-default mb-0 geb fs-24"}
                                    style={{
                                        textTransform: "uppercase",
                                        letterSpacing: "1px",
                                    }}
                                >
                                    {customer.clientFirm}{" "}
                                    <Filter
                                        size="20"
                                        onClick={() => {
                                            setFilterSwitch({
                                                ...filterSwitch,
                                                flag: !filterSwitch.flag,
                                            });
                                        }}
                                        style={{
                                            verticalAlign: "revert",
                                            cursor: "pointer",
                                            fill: filterSwitch.flag ? "black" : "transparent",
                                        }}
                                    />
                                </h2>
                            </div>

                            <div class="dropdown">
                                <button
                                    class="btn btn-primary btn-icon-only"
                                    onClick={() => {
                                        setHeadMenu((prev) => !prev);
                                        setMoreMenu(-1);
                                    }}
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <FiMoreVertical className="text-white" style={{ fontSize: 18 }} />
                                </button>
                                <ul
                                    class={
                                        headMenu
                                            ? "dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                                            : "dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                    }
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <span onClick={switchingMonthly} class="dropdown-item" style={{ fontWeight: 600 }}>
                                            {monthly.switch ? "Cancel" : "Preview By Month"}
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={(evt) => {
                                                setOpen((prev) => {
                                                    return { materialSidebar: false, historySidebar: true };
                                                });
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Received History
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                setModals({ ...modals, batchUpdate: true });
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Batch Receive
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                onAddEntryHandler();
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Add New Entry
                                        </span>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                        <span class="dropdown-item  style={{fontWeight:600}}disabled text-primary" style={{ fontWeight: 600 }}>
                                            <HiOutlineDownload />
                                            Download Sheet
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                onTapDownload(customer._id, "tm");
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            This Month
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                onTapDownload(customer._id, "ltm");
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Last 3 Months
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                onTapDownload(customer._id, "lsm");
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Last 6 month
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                onTapDownload(customer._id, "ft");
                                                setHeadMenu((prev) => !prev);
                                            }}
                                            class="dropdown-item"
                                            style={{ fontWeight: 600 }}
                                        >
                                            All Time
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>
                        <Table
                            className={props.darkModeFlag === "false" ? "align-items-center table-dark table-flush" : "align-items-center table table-flush"}
                            responsive
                        >
                            <thead className={props.darkModeFlag === "false" ? "thead-dark" : "thead-light"}>
                                <tr>
                                    {filterSwitch.flag && (
                                        <th scope="col">
                                            <input type="checkbox" onChange={selectAllEntries} />
                                        </th>
                                    )}
                                    <th scope="col">Date</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">SubTotal(&#8377;)</th>
                                    <th scope="col">Due(&#8377;)</th>
                                    <th scope="col">Received(&#8377;)</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {splittedEntries.map((entry, index) => {
                                    let stats = { cgst: [], sgst: [] };
                                    stats.cgst.push((entry.amount * (entry.cgst / 50)) / 2);
                                    return (
                                        <ListItem
                                            filterFlag={filterSwitch.flag}
                                            onSelectEntryListner={onSelectEntryListner}
                                            entry={entry}
                                            tax={stats}
                                            setMoreMenu={setMoreMenu}
                                            moreMenu={moreMenu}
                                            idx={index}
                                            key={index}
                                            onTapDeleteEntry={onTapDeleteEntry}
                                            onTapEdit={onTapEdit}
                                        />
                                    );
                                })}
                            </tbody>
                        </Table>
                        <CardFooter>
                            <ClientPagination
                                onDoneEntriesSelection={() => {
                                    setDataForInvoice({ ...dataForInvoice, ready: false, view: false });
                                    setModals({ ...modals, invoice: true });
                                }}
                                ready={dataForInvoice.ready}
                                onSaveInvoice={() => {
                                    setModals({ ...modals, confirmSave: true });
                                }}
                                filterSwitch={filterSwitch.flag}
                                clients={entries}
                                bottomText={false}
                                clientPerPage={entriesPerPage}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </CardFooter>
                    </Card>
                </Col>
                <MontlyModal
                    onCancelHandler={() => {
                        setMonthly({ date: new Date(), modal: false, switch: false });
                    }}
                    onDoneMonthlyListner={onDoneMonthlyListner}
                    modal={monthly.modal}
                    onChangeDate={(evt) => {
                        setMonthly({ ...monthly, date: evt.target.value });
                    }}
                    date={monthly.date}
                />
                <GenrateInvoiceNumber
                    modal={modals.invoice}
                    onCancelHandler={() => {
                        setModals({ ...modals, invoice: false });
                    }}
                    onGenerateInvoice={(date, invNo) => {
                        onDoneEntriesSelection(date, invNo);
                    }}
                />
                <BatchUpdateModal
                    modal={modals.batchUpdate}
                    onCancelHandler={() => {
                        setModals({ ...modals, batchUpdate: false });
                    }}
                    onSubmitHandler={onBatchUpdate}
                />
                <ConfirmModal
                    onCancelHandler={() => {
                        setModals({ ...modals, confirmSave: false });
                    }}
                    modal={modals.confirmSave}
                    onSubmitHandler={onSaveInvoice}
                />
            </Row>

            {dataForInvoice.view && (
                <Row className="mt-2">
                    <Col xl="3"></Col>
                    <Col xl="6">
                        <Main invoice={dataForInvoice} />
                    </Col>
                    <Col xl="3"></Col>
                </Row>
            )}
        </>
    );
};

export default ClientComponentPage;
