import React, { useEffect, useState, useRef, useCallback } from "react";
import LiteHeader from "../../../Common/Header/LiteHeader";

import { userBackend } from "../user_backend";

import { Row, Container, Col, Card, CardBody, Button, CardHeader, CardFooter } from "reactstrap";
import AddDetail from "./AddDetail";
import UploadModal from "./UploadModal";
import Loader from "../../../global/Loader/Loader";

const UserProfileIndex = (props) => {
      const inputRef = useRef(null);
      const [uid] = useState(window.localStorage.getItem("uid"));
      const [form, setForm] = useState({
            name: "",
            email: "",
            firm: "",
            phone: "",
            address: "",
            gst: "",
            bank_name: "",
            account_no: "",
            ifsc: "",
      });
      const [userPhoto, setUserPhoto] = useState(null);
      const [error, setError] = useState(false);
      const [loading, setLoading] = useState(false);
      const [urlx, setUrl] = useState(false);

      const [progressVar, setProgressVar] = useState(0);
      const [modal, setModal] = useState(false);
      const [modalNew, setModalNew] = useState(false);

      const toggle = (e) => {
            try {
                  setModal(!modal);
            } catch (err) {
                  setModal(!modal);
            }
      };

      const userInfo = useCallback(async () => {
            try {
                  const formData = new FormData();
                  formData.set("uid", uid);
                  const res = await userBackend.getUserInfo(formData, window.localStorage.getItem("session_token"));

                  setForm({
                        ...form,
                        name: res.data.name,
                        email: res.data.email,
                        firm: res.data.firm,
                        address: res.data.address,
                        phone: res.data.phone,
                        gst: res.data.gst,
                        bank_name: res.data.bank_name,
                        account_no: res.data.account,
                        ifsc: res.data.ifsc,
                  });
                  setUrl(`${process.env.REACT_APP_API_URL}/uploads/${res.data.url}`);
                  setLoading(true);
            } catch (error) {
                  console.log(error.message);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [uid]);

      useEffect(() => {
            userInfo();
      }, [userInfo]);

      const onCancelHandler = () => {
            setModalNew(false);
            setModal(false);
      };

      const uploadImage = async () => {
            const formData = new FormData();
            formData.append("userDP", userPhoto);
            formData.set("uid", uid);
            try {
                  const res = await userBackend.addUserImage(
                        formData,
                        setProgressVar,
                        window.localStorage.getItem("session_token")
                  );
                  console.log(res.data.url);
                  setUrl(`${process.env.REACT_APP_API_URL}/uploads/${res.data.url}`);
                  setModal(false);
            } catch (error) {}
      };

      const handlePhoto = (e) => {
            setError(false);
            try {
                  const file = e.target.files[0];
                  const validFileType = ["image/jpg", "image/jpeg", "image/png"];
                  if (validFileType.includes(file["type"])) {
                        setUserPhoto(file);
                  } else {
                        setError(true);
                  }
            } catch (err) {
                  alert(err);
            }
      };

      const detailSubmit = async () => {
            try {
                  const formData = new FormData();
                  for (let key in form) {
                        if (form[key] === "") {
                              return setError(`Invalid ${form[key]}.`);
                        }
                        formData.set(key, form[key]);
                  }
                  formData.set("uid", uid);
                  const res = await userBackend.addUserInfo(formData, window.localStorage.getItem("session_token"));
                  console.log(res.data);
                  setForm({
                        ...form,
                        name: res.data.name,
                        email: res.data.email,
                        firm: res.data.firm,
                        address: res.data.address,
                        phone: res.data.phone,
                        gst: res.data.gst,
                        bank_name: res.data.bank_name,
                        account_no: res.data.account_no,
                        ifsc: res.data.ifsc,
                  });
                  setModalNew(false);
            } catch (error) {
                  console.log(error);
                  setModalNew(false);
            }
      };

      return (
            <>
                  <LiteHeader bg={"primary"} />
                  <Container className="mt--8 text-center" fluid>
                        {loading ? (
                              <Row>
                                    <Col xl="3"></Col>
                                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="5">
                                          <Card className="card-profile shadow">
                                                <Row className="justify-content-center">
                                                      <Col className="order-lg-2" lg="3">
                                                            <div className="card-profile-image">
                                                                  {urlx ? (
                                                                        <a href="#pablo">
                                                                              <img
                                                                                    className="rounded-square"
                                                                                    alt="logo"
                                                                                    src={urlx}
                                                                              />
                                                                        </a>
                                                                  ) : (
                                                                        <h6 className="text-muted">
                                                                              Upload a logo or wait for load
                                                                        </h6>
                                                                  )}
                                                            </div>
                                                      </Col>
                                                </Row>
                                                <CardHeader className="text-center border-0 mt-7 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
                                                <CardBody className="pt-0 pt-md-4">
                                                      <div className="text-center">
                                                            <span className="geb">
                                                                  <span className="fs-18 h5">{form.name}</span>
                                                                  <span className="font-weight-light"></span>
                                                            </span>
                                                            <div className="h5 font-weight-300">
                                                                  <i className="ni location_pin mr-2" />
                                                                  Firm: {form.firm}
                                                                  <br />
                                                                  Email: {form.email}
                                                            </div>
                                                            <hr className="my-4" />
                                                            <p>
                                                                  Address :{" "}
                                                                  <span className="ml-1 h5 geb fs-18">{form.address}</span>
                                                            </p>
                                                            <p>
                                                                  Phone :{" "}
                                                                  <span className="ml-1 h5 geb fs-18">{form.phone}</span>
                                                            </p>
                                                            <p>
                                                                  Gst : <span className="ml-1 h5 geb fs-18">{form.gst}</span>
                                                            </p>
                                                            <p>
                                                                  Bank :
                                                                  <span className="ml-1 h5 geb fs-18">{form.bank_name}</span>
                                                            </p>
                                                            <p>
                                                                  Account No :
                                                                  <span className="ml-1 h5 geb fs-18">
                                                                        {form.account_no}
                                                                  </span>
                                                            </p>
                                                            <p>
                                                                  IFSC :{" "}
                                                                  <span className="ml-1 h5 geb fs-18">{form.ifsc}</span>
                                                            </p>
                                                            <Row className="mt-5">
                                                                  <Col>
                                                                        <Button
                                                                              color="primary"
                                                                              className="btn btn-primary btn-sm fira"
                                                                              onClick={(e) => setModalNew(!modalNew)}
                                                                        >
                                                                              Add/Edit Detail
                                                                        </Button>
                                                                  </Col>
                                                                  <Col>
                                                                        <Button
                                                                              color="success"
                                                                              size="sm"
                                                                              className="fira"
                                                                              onClick={(e) => setModal(!modal)}
                                                                        >
                                                                              Upload/Change Picture
                                                                        </Button>
                                                                  </Col>
                                                            </Row>
                                                      </div>
                                                </CardBody>
                                                <CardFooter>
                                                      <div className="alert text-muted">
                                                            <span className="text-danger">Note:</span> Once you fill all the
                                                            detail it will be visible here and will use for Invoice.
                                                      </div>
                                                </CardFooter>
                                          </Card>
                                    </Col>
                              </Row>
                        ) : (
                              <Loader />
                        )}
                  </Container>
                  <div></div>
                  <UploadModal
                        error={error}
                        modal={modal}
                        toggle={toggle}
                        uploadImage={uploadImage}
                        inputRef={inputRef}
                        userPhoto={userPhoto}
                        progressVar={progressVar}
                        handlePhoto={handlePhoto}
                  />
                  <AddDetail
                        modalNew={modalNew}
                        form={form}
                        setForm={setForm}
                        onSubmitHandler={detailSubmit}
                        onCancelHandler={onCancelHandler}
                  />
            </>
      );
};

export default UserProfileIndex;
