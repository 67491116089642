import React from "react";
import { Collapse, Navbar, Container, Row, Col } from "reactstrap";
import {  X } from "react-feather";
import {getDate} from "../../../Common/DateAndTime/getDate";
const HistorySidebar = ({
	batchRecieved,
	setOpen,
	cname
	
}) => {
	const [collapseOpen, setCollapseOpen] = React.useState(false);

	const toggleCollapse = () => {
		setCollapseOpen(!collapseOpen);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	console.log(new Date());
	return (
		<Navbar
			className={`navbar-vertical fixed-left navbar-light bg-white shadow-lg msidebar`}
			style={{ zIndex: 10 }}
			expand="md"
			id="sidenav-main"
		>
			<Container fluid>
				<Collapse
					navbar
					isOpen={collapseOpen}
					toggle={toggleCollapse}
					className="b-0"
				>
					<div className="navbar-collapse-header d-md-none">
						<Row>
							<Col className="collapse-close" xs="7">
								<button className="navbar-toggler" onClick={closeCollapse}>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					<span className="d-flex mb-3">
						<X className="ml-auto cp" onClick={(e) => setOpen(prev=>{return {...prev,historySidebar:false}})} />
					</span>
					<Row>
						<Col>
							<h6 className="text-uppercase text-muted ls-1 mb-0">{`${cname}'s`}</h6>
							<h2
								className={"text-default mb-2 geb fs-24"}
								style={{
									textTransform: "uppercase",
									letterSpacing: "1px",
								}}
							>
								Received
							</h2>
						</Col>
						
					</Row>
					<table className="table table-flush rounded">
						<thead className="thead-light pp">
							<th scope="col">#</th>
							<th scope="col">Date</th>
							<th scope="col">Amount</th>
						</thead>
						<tbody>
							{batchRecieved.map((el, index) => (
								<tr key={index} className="pp">
									<th scope="row">{index + 1}</th>
                                    <th scope="row">{getDate(el.date)}</th>
									<th scope="row">₹{el.amount}</th>
								</tr>
							))}
						</tbody>
					</table>
				</Collapse>
			</Container>
		</Navbar>
	);
};

export default HistorySidebar;
