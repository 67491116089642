import axios from "axios";

class EntryBackend {
	addNewEntry(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/entry/add`,
					// `http://localhost:5000/entry/add`,
					formData,
					{ headers: { "SESSION-TOKEN": stoken } }
				);
				if (res.data.code !== 200) throw res;
				resolve(res.data);
			} catch (err) {
				console.log(err);
				reject(err);
			}
		});
	}
	editEntry(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					// `http://localhost:5000/entry/update`,
					`${process.env.REACT_APP_API_URL}/entry/update`,
					formData,
					{ headers: { "SESSION-TOKEN": stoken } }
				);
				if (res.data.code !== 200) throw res;
				resolve(res.data);
			} catch (err) {
				console.log(err);
				reject(err);
			}
		});
	}
	deleteEntry(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/entry/remove`,
					formData,
					{ headers: { "SESSION-TOKEN": stoken } }
				);
				if (res.data.code !== 200) throw res;
				resolve(res.data);
			} catch (err) {
				console.log(err);
				reject(err);
			}
		});
	}
}

export let entryBackend = new EntryBackend();
