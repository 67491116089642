import React, { useState, useEffect, useCallback } from "react";
/* */
import { Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Footer from "../../Common/Footers/AdminFooter";
import { Container } from "reactstrap";
import AdminNavbar from "../../Common/Navbars/AdminNavbar";
import routes from "../../routes";
import more_routes from "../../moreroutes";
import Index from "../../Views/Index";

import UserProfileIndex from "../../Views/UserProfile/component/UserProfileIndex";
import { userBackend } from "../../Views/UserProfile/user_backend";
import AddClient from "../../Views/Client/component/AddClient";
import AddMaterial from "../../Views/Material/component/AddMaterial";
import Trash from "../../Views/Trash/components/Trash";
import InvoiceIndex from "../../Views/Invoice/Component/InvoiceIndex";
import ChallanIndex from "../../Views/Challan/components/Index";
import SalesIndex from "../../Views/Sales/components/SalesIndex";

const AdminLayout = ({ uid }) => {
    const [darkMode, setDarkMode] = useState("true");

    const [email] = useState(window.localStorage.getItem("email"));

    const [darkModeColor] = useState({
        sidebarColor: "bg-white",
        sidebarDarkColor: "bg-default",
        tableColor: "bg",
        textColor: "text-white",
        tableDarkColor: "bg-default",
        textDarkColor: "text-default",
    });

    const darkModeToggle = () => {
        if (darkMode === "false") {
            window.localStorage.setItem("mode", "true");
            setDarkMode({ darkMode: "true" });
        } else {
            window.localStorage.setItem("mode", "false");
            setDarkMode({ darkMode: "false" });
        }
    };

    const [heading, setHeading] = useState("Dashboard");
    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        return setPath(window.location.pathname);
    }, []);

    useEffect(() => {
        const temp = path.split("/");
        if (temp[1] === "") {
            return setHeading("Dashboard");
        } else {
            return setHeading(temp[1]);
        }
    }, [path]);

    const [state, setState] = useState({
        clients: [],
        dates: [],
        user: {},
        imageUrl: ``,
    });

    const getUserInfo = useCallback(async () => {
        if (uid) {
            const formData = new FormData();
            formData.set("uid", uid);
            const res = await userBackend.getUserInfo(formData, window.localStorage.getItem("session_token"));
            setState({
                ...state,
                user: res.data,
                imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${res.data.url}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    useEffect(() => {
        getUserInfo();
        if (!window.localStorage.getItem("inv_number")) {
            window.localStorage.setItem("inv_number", 1);
        }
    }, [getUserInfo]);

    return (
        <>
            <Sidebar
                darkModeColor={darkModeColor}
                darkModeFlag={darkMode}
                darkMode={darkModeToggle}
                routes={routes}
                image={state.imageUrl}
                email={email}
                heading={heading}
                more_routes={more_routes}
            />
            <div className={darkMode === "true" ? "main-content bg-gradient-primary" : "main-content"}>
                <AdminNavbar email={email} uid={uid} url={state.imageUrl} brandText={heading} />
                <Switch>
                    <Redirect to="/admin/dashboard" exact from="/admin/" />
                    <Route path="/admin/dashboard" exact component={Index} />
                    <Route path="/admin/trash" exact component={Trash} />
                    <Route path="/admin/invoices" exact render={() => <InvoiceIndex user={state.user} />} />
                    <Route path="/admin/sales" exact render={() => <SalesIndex user={state.user} />} />
                    <Route path="/admin/challan" exact component={ChallanIndex} />
                    <Route path="/admin/customers" exact component={AddClient} />
                    <Route path="/admin/accounts" exact component={UserProfileIndex} />
                    <Route path="/admin/products" exact component={AddMaterial} />
                </Switch>

                <Container className={darkMode === "true" ? "bg-gradient-primary" : ""} fluid>
                    <Footer darkModeFlag={darkMode} />
                </Container>
            </div>
        </>
    );
};
export default AdminLayout;
