import React from "react";
import {
	ModalBody,
	Col,
	Modal,
	ModalHeader,
	Row,
	FormGroup,
	Input,
	ModalFooter,
	Button,
} from "reactstrap";

const ModalEditMaterial = ({
	onCancelHandler,
	addNewMaterial,
	modal,
	onChangeMaterial,
	state,
}) => {
	return (
		<Modal
			isOpen={modal}
			toggle={onCancelHandler}
			style={{ zIndex: 10000000000 }}
		>
			<ModalHeader className="bg " toggle={onCancelHandler}>
				<h3 className="text fs-24 geb">Edit Product</h3>
			</ModalHeader>
			<ModalBody className="bg">
				<Row>
					<Col xl="6">
						<FormGroup>
							<label className="form-control-label  pp fs-12">Name</label>
							<Input
								className="form-control-alternative nn"
								placeholder="Product"
								type="text"
								name="material_name"
								value={state.material_name}
								onChange={onChangeMaterial}
							/>
						</FormGroup>
					</Col>
					<Col xl="6">
						<FormGroup>
							<label className="form-control-label pp fs-12 ">Rate</label>
							<Input
								className="form-control-alternative nn"
								placeholder="Rate"
								type="number"
								name="material_rate"
								value={state.material_rate}
								onChange={onChangeMaterial}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter className="bg">
				<Button
					color="primary"
					className="btn fira btn-success"
					onClick={addNewMaterial}
				>
					Add
				</Button>{" "}
				<Button
					color="secondary"
					className="btn fira btn-warning"
					onClick={onCancelHandler}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalEditMaterial;
