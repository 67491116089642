import React, { useEffect, useState, useCallback } from "react";

import LiteHeader from "../../../Common/Header/LiteHeader";
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Button,
	Row,
	Col,
	Container,
	CardFooter,
} from "reactstrap";

import { materialsBackend } from "../material_backend";
import { clientsBackend } from "../../Client/client_backend";
import Loader from "../../../global/Loader/Loader";
import DropdownList from "../../../global/DropdownList";
import ModalCopyProducts from "./ModalCopyProducts";

const AddMaterial = ({ darkModeFlag }) => {
	const [uid] = React.useState(window.localStorage.getItem("uid"));
	const [loading, setLoading] = React.useState(false);
	const [status, setStatus] = React.useState(false);
	const [customer, setCustomers] = useState([]);
	const [copyModal, setCopyModal] = useState(false);
	const [optCustomers, setOptCustomers] = useState([]);
	const [form, setForm] = useState({
		material_name: "",
		material_rate: 0,
		client_id: "",
	});
	const initialState = {
		material_name: "",
		material_rate: 0,
		client_id: "",
	};
	const [copyIds, setCopyIds] = useState({
		copy_from: "",
		copy_to: "",
		status: false,
	});
	const initialCopy = {
		copy_from: "",
		copy_to: "",
		status: false,
	};

	async function handleSubmit(e) {
		try {
			const formData = new FormData();
			formData.set("uid", uid);
			formData.set("client_id", form.client_id);
			formData.set("material_name", form.material_name);
			formData.set("material_rate", form.material_rate);
			await materialsBackend.addNewMaterial(
				formData,
				window.localStorage.getItem("session_token")
			);
			setStatus(true);
			setTimeout(() => {
				setForm(initialState);
				setStatus(false);
			}, 1500);
		} catch (error) {}
	}

	const onValuesChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const getClients = useCallback(async () => {
		try {
			const formData = new FormData();
			formData.set("uid", uid);
			const res = await clientsBackend.getOnlyClients(
				formData,
				window.localStorage.getItem("session_token")
			);
			setCustomers(res.data);
			setLoading(true);
		} catch (error) {
			console.log(error);
		}
	}, [uid]);

	useEffect(() => {
		getClients();
	}, [getClients]);

	const onCustomerChange = (id) => {
		setForm({ ...form, client_id: id });
	};

	const onCancelHandler = () => {
		setCopyModal(false);
		setCopyIds(initialCopy);
	};

	const onChangeTo = (id) => {
		if (!id) return;
		setCopyIds({ ...copyIds, copy_to: id });
		let temp = JSON.parse(JSON.stringify(customer));
		const index = customer.findIndex((el) => el._id === id);
		temp.splice(index, 1);
		setOptCustomers([...temp]);
	};

	const onChangeFrom = (id) => {
		if (!id) return;
		setCopyIds({ ...copyIds, copy_from: id });
	};

	const onCopyHandler = async () => {
		try {
			const formData = new FormData();
			formData.set("copy_to", copyIds.copy_from);
			formData.set("copy_from", copyIds.copy_to);
			formData.set("uid", uid);
			await materialsBackend.copyMaterials(
				formData,
				window.localStorage.getItem("session_token")
			);
			setCopyIds({ ...copyIds, status: true });
			setTimeout(() => {
				setCopyIds(initialCopy);
			}, 1200);
		} catch (error) {
			console.log(error);
		}
	};

	return loading ? (
		<>
			<LiteHeader bg={"primary"} />
			<Container fluid className="mt--5">
				<Row>
					<Col xl="2"></Col>
					<Col className="order-xl-1 mt--7 " xl="8">
						<Card className="bg-secondary shadow-lg">
							<CardHeader className="bg-white border-0">
								<Row className="align-items-center geb">
									<div className="col">
										<h6 className="text-uppercase text-muted ls-1 mb-1">Add</h6>
										<h2
											className={
												darkModeFlag === "false"
													? "text-white mb-0 geb fs-24 "
													: "text-default mb-0 geb fs-24"
											}
											style={{
												textTransform: "uppercase",
												letterSpacing: "1px",
											}}
										>
											Products
										</h2>
									</div>
									<Button
										color="primary"
										className="fira shadow mr-3"
										onClick={(e) => setCopyModal(true)}
									>
										Copy
									</Button>
								</Row>
							</CardHeader>
							<CardBody className="bg-white">
								{status ? (
									<div className="alert alert-success ">Successfully added</div>
								) : (
									<></>
								)}
								<h6 className="heading-small text-muted mb-4 geb ">
									Product information
								</h6>
								<Form>
									<Row>
										<Col lg="6">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Customers
												</label>
												<DropdownList
													className="form-control-alternative"
													clients={customer}
													placeholder="Client-Names"
													type="text"
													cid={form.client_id}
													onChange={onCustomerChange}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg="6">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Name
												</label>
												<Input
													className="form-control-alternative nn"
													onChange={(e) => onValuesChange(e)}
													value={form.material_name}
													name="material_name"
													placeholder="Product"
													type="text"
												/>
											</FormGroup>
										</Col>
										<Col lg="6">
											<FormGroup>
												<label className="form-control-label pp fs-12">
													Rate
												</label>
												<Input
													className="form-control-alternative nn"
													onChange={(e) => onValuesChange(e)}
													value={form.material_rate}
													name="material_rate"
													placeholder="Rate"
													type="number"
												/>
											</FormGroup>
										</Col>
									</Row>
									<div className="text-left ">
										<Button
											color="primary"
											className="fira shadow my-2"
											onClick={handleSubmit}
										>
											Add
										</Button>
									</div>
								</Form>
							</CardBody>
							<CardFooter
								className={darkModeFlag === "false" ? "bg-default" : ""}
							>
								<div className="col-xl-9 text-primary text-left ml--3">
									<span className="fs-12 fira ">
										Note: To copy all products from a client to new will replace
										existing one
									</span>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<ModalCopyProducts
					modal={copyModal}
					onCancelHandler={onCancelHandler}
					customers={customer}
					state={copyIds}
					onChangeTo={onChangeTo}
					optCustomers={optCustomers}
					onChangeFrom={onChangeFrom}
					onCopyHandler={onCopyHandler}
					status={copyIds.status}
				/>
			</Container>
		</>
	) : (
		<Loader />
	);
};

export default AddMaterial;
