import React, { useState, useEffect, useCallback } from "react";
import ClientComponents from "./ClientComponents";

import { userBackend } from "../../UserProfile/user_backend";
import Loader from "../../../global/Loader/Loader";
import { clientsBackend } from "../client_backend";
import MaterialSidebar from "./MaterialSidebar";
import ModalAddMaterial from "./ModalAddMaterial";
import ModalEditMaterial from "./ModalEditMaterial";
import ModalDeleteMaterial from "./ModalDelete";
import { materialsBackend } from "../../Material/material_backend";
import HistorySidebar from "./HistorySidebar";

const PreClientComponents = ({ uid }) => {
      const [loading, setLoading] = useState(false);
      const [user, setUser] = useState({});
      const [client_id] = useState(window.location.pathname.split("/")[2]);
      const [open, setOpen] = useState({materialSidebar:false,historySidebar:false});
      const stoken = window.localStorage.getItem("session_token");
      const [materialForm, setMaterialForm] = useState({
            material_id: "",
            material_name: "",
            material_rate: "",
      });
      const [addModal, setAddModal] = useState(false);
      const [editModal, setEditModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);

      const initialMaterialState = {
            material_id: "",
            material_name: "",
            material_rate: "",
      };

      const onCancelHandler = () => {
            setMaterialForm(initialMaterialState);
            setAddModal(false);
            setEditModal(false);
            setDeleteModal(false);
      };

      const onChangeMaterial = (e) => {
            setMaterialForm({ ...materialForm, [e.target.name]: e.target.value });
      };

      const onEditBtnHandler = (id) => {
            const material = customer.materials.filter((el) => el._id === id);
            setMaterialForm({
                  ...materialForm,
                  material_id: id,
                  material_name: material[0].material_name,
                  material_rate: material[0].material_rate,
            });
            setEditModal(true);
      };

      const onDeleteBtnHandler = (id) => {
            setMaterialForm({ ...materialForm, material_id: id });
            setDeleteModal(true);
      };

      const addNewMaterial = async () => {
            try {
                  const formData = new FormData();
                  formData.set("uid", uid);
                  formData.set("client_id", client_id);
                  formData.set("material_name", materialForm.material_name);
                  formData.set("material_rate", materialForm.material_rate);
                  const res = await materialsBackend.addNewMaterial(formData, stoken);
                  let temp = customer.materials;
                  temp = [...temp, res.data];
                  setCustomer({ ...customer, materials: temp });
                  setAddModal(false);
                  setMaterialForm(initialMaterialState);
            } catch (error) {
                  console.log(error);
            }
      };

      const editMaterial = async () => {
            try {
                  const formData = new FormData();
                  formData.set("material_id", materialForm.material_id);
                  formData.set("material_name", materialForm.material_name);
                  formData.set("material_rate", materialForm.material_rate);
                  const res = await materialsBackend.editMaterial(formData, stoken);
                  let temp = customer.materials;
                  const index = temp.findIndex((el) => el._id === materialForm.material_id);
                  temp.splice(index, 1);
                  temp.splice(index, 0, res.data);
                  setCustomer({ ...customer, materials: temp });
                  setEditModal(false);
                  setMaterialForm(initialMaterialState);
            } catch (error) {
                  console.log(error);
            }
      };

      const deleteMaterial = async () => {
            try {
                  const formData = new FormData();
                  formData.set("material_id", materialForm.material_id);
                  formData.set("client_id", client_id);
                  await materialsBackend.deleteMaterial(formData, stoken);
                  let temp = customer.materials;
                  const index = temp.findIndex((el) => el._id === materialForm.material_id);
                  temp.splice(index, 1);
                  setCustomer({ ...customer, materials: temp });
                  setDeleteModal(false);
                  setMaterialForm(initialMaterialState);
            } catch (error) {
                  console.log(error);
            }
      };

      const getUserDetail = useCallback(async () => {
            try {
                  const formData = new FormData();
                  formData.set("uid", uid);
                  const res = await userBackend.getUserInfo(formData, stoken);
                  setUser(res.data);
            } catch (error) {
                  console.log(error);
            }
      }, [uid, stoken]);

      const [customer, setCustomer] = useState({});

      const getClientDetail = useCallback(async () => {
            try {
                  const formData = new FormData();
                  formData.set("client_id", client_id);
                  const res = await clientsBackend.getClientDetail(formData, stoken);
                  const customer = res.data;
                  // adding a flag `checked` to entries for invoicing feature
                  const modifiedEntries = customer.entries.map((el) => {
                        return { ...el, checked: false };
                  });
                  customer.entries = modifiedEntries;
                  setCustomer(customer);
                  setLoading(true);
            } catch (error) {
                  console.log(error);
            }
      }, [client_id, stoken]);

      useEffect(() => {
            getUserDetail();
            getClientDetail();
      }, [getUserDetail, getClientDetail]);

      return loading ? (
            <div className="rltive">
                  {open.materialSidebar && (
                        <>
                              <MaterialSidebar
                                    materials={customer.materials}
                                    setOpen={setOpen}
                                    cname={customer.clientFirm}
                                    setAddModal={setAddModal}
                                    onEditBtnHandler={onEditBtnHandler}
                                    onDeleteBtnHandler={onDeleteBtnHandler}
                              />
                              <ClientComponents user={user} customer={customer} setOpen={setOpen} cid={client_id} setCustomer={setCustomer} uid={uid} />
                              <ModalAddMaterial
                                    modal={addModal}
                                    onCancelHandler={onCancelHandler}
                                    addNewMaterial={addNewMaterial}
                                    state={materialForm}
                                    onChangeMaterial={onChangeMaterial}
                              />
                              <ModalEditMaterial
                                    modal={editModal}
                                    onCancelHandler={onCancelHandler}
                                    addNewMaterial={editMaterial}
                                    state={materialForm}
                                    onChangeMaterial={onChangeMaterial}
                              />
                              <ModalDeleteMaterial modal={deleteModal} onCancelHandler={onCancelHandler} onSubmitHandler={deleteMaterial} />
                        </>
                  )}
                  {open.historySidebar && (
                        <>
                              <HistorySidebar
                                    batchRecieved={customer.batchUpdates}
                                    setOpen={setOpen}
                                    cname={customer.clientFirm}
                              />
                              <ClientComponents user={user} customer={customer} setOpen={setOpen} cid={client_id} setCustomer={setCustomer} uid={uid} />
                        </>
                  )}
                    {!open.historySidebar && !open.materialSidebar && (<ClientComponents user={user} customer={customer} setOpen={setOpen} setCustomer={setCustomer} cid={client_id} uid={uid} />)}
            </div>
      ) : (
            <Loader />
      );
};

export default PreClientComponents;
