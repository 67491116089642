import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 20,
        borderWidth: 0,
        borderColor: "transparent",
    },

    description: {
        textAlign: "left",
        borderRightColor: "#8f8f8f",
        paddingLeft: 5,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    srno: {
        width: "5%",
        borderRightColor: "#8f8f8f",
        textAlign: "center",
        paddingRight: 8,
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    date: {
        width: "10%",
        borderRightColor: "#8f8f8f",
        textAlign: "left",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    qty: {
        width: "10%",
        borderRightColor: "#8f8f8f",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    rate: {
        width: "15%",
        borderRightColor: "#5f5f5f",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    amount: {
        paddingLeft: "5%",
        width: "25%",
        textAlign: "center",
        fontFamily: "OSM",
        fontSize: "9pt",
    },
    headerContainer: {
        flexDirection: "row",
        borderTopColor: "#5f5f5f",
        borderBottomColor: "#5f5f5f",
        borderBottomWidth: 1,
        borderTopWidth: 1,
        fontSize: "7pt",
        alignItems: "center",
        height: 24,
        flexGrow: 1,
    },
    dataRow: {
        flexDirection: "row",
        borderBottomColor: "#8f8f8f",
        borderBottomWidth: 0.3,
        alignItems: "center",
        height: 30,
        fontWeight: "bold",
    },
});

const InvoiceItemsTable = ({ invoice }) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.entries} />
        <InvoiceTableFooter items={invoice.entries} />
    </View>
);

export default InvoiceItemsTable;
