import axios from "axios";

class MaterialBackend {
	getMaterials(entry_id) {
		return new Promise((resolve, reject) => {
			this.database
				.collection("material")
				.doc(entry_id)
				.get()
				.then((fetched) => {
					let entry = this.helper.toMaterialEntry(
						entry_id,
						fetched.data()["client_id"],
						fetched.data()
					);
					resolve(entry);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	addNewMaterial(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/material/add`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}

	editMaterial(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/material/update`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteMaterial(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/material/remove`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}

	copyMaterials(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/material/copy`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}
}

export let materialsBackend = new MaterialBackend();
