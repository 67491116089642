import React from "react";
import { Button, Badge } from "reactstrap";
import { Edit, Trash } from "react-feather";
const SheetTableItems = ({ onTapActions, entry }) => {
	return (
		<tr className="pp-l">
			<th scope="row">
				{entry.issued === "true" ? (
					<Badge size="sm" color="success">
						<i className="fa fa-check mr-0"></i>
					</Badge>
				) : (
					<Badge size="sm" color="warning">
						pending
					</Badge>
				)}
			</th>
			<th scope="row">{entry.material}</th>
			<th scope="row">{entry.description}</th>
			<th scope="row">{entry.qty}</th>
			<th scope="row">{entry.rate}</th>
			<th scope="row">&#8377;{entry.amount}</th>
			<th scope="row">
				&#8377;{(entry.amount * (entry.cgst / 100)).toFixed(2)}({entry.cgst}
				%)
			</th>
			<th scope="row">
				&#8377;{(entry.amount * (entry.cgst / 100)).toFixed(2)}({entry.cgst}
				%)
			</th>
			<th scope="row">&#8377;{entry.total}</th>
			<th scope="row">
				&#8377;{entry.advance}
				{Number(entry.total) === 0 ? <Badge color="success">PAID</Badge> : <Badge color="warning">RECEIVED</Badge>}
			</th>
			<th scope="row">
				<Button size="sm" color="warning" onClick={(e) => onTapActions(entry._id, "EDIT")}>
					<Edit size="14" />
				</Button>
				<Button size="sm" color="danger" onClick={(e) => onTapActions(entry._id, "DELETE")}>
					<Trash size="14" />
				</Button>
			</th>
		</tr>
	);
};

export default SheetTableItems;
