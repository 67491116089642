import React, { useEffect, useState } from "react";
import { Collapse, Navbar, Container, Row, Col, Button } from "reactstrap";
import { Edit, Trash2, X } from "react-feather";
import useInnerWidth from "../../../Common/DateAndTime/useInnerWidth";
const MaterialSidebar = ({ materials, setOpen, cname, setAddModal, onEditBtnHandler, onDeleteBtnHandler }) => {
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    const toggleCollapse = () => {
        setCollapseOpen(!collapseOpen);
    };

    const innerW = useInnerWidth();
    const [absoluteMenu, setAbsoluteMenu] = useState(false);

    useEffect(() => {
        if (innerW < 767) {
            setAbsoluteMenu(true);
            document.getElementById("sidenav-main-nav2").classList.remove("navbar");
        } else {
            setAbsoluteMenu(false);
            document.getElementById("sidenav-main-nav2").classList.add("navbar");
        }
    }, [innerW]);

    return (
        <Navbar className={`navbar-vertical fixed-left navbar-light bg-white shadow-lg msidebar`} style={{ zIndex: 10 }} expand="md" id="sidenav-main-nav2">
            <Container fluid>
                <Collapse navbar isOpen={collapseOpen} toggle={toggleCollapse} className={absoluteMenu ? "b-0 show" : "b-0"}>
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            <Col className="collapse-close" xs="7">
                                <button
                                    className="navbar-toggler"
                                    onClick={(e) =>
                                        setOpen((prev) => {
                                            return { ...prev, materialSidebar: false };
                                        })
                                    }
                                >
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>
                    {!absoluteMenu && (
                        <span className="d-flex mb-3">
                            <X
                                className="ml-auto cp"
                                onClick={(e) =>
                                    setOpen((prev) => {
                                        return { ...prev, materialSidebar: false };
                                    })
                                }
                            />
                        </span>
                    )}
                    <Row>
                        <Col>
                            <h6 className="text-uppercase text-muted ls-1 mb-0">{`${cname}'s`}</h6>
                            <h2
                                className={"text-default mb-2 geb fs-24"}
                                style={{
                                    textTransform: "uppercase",
                                    letterSpacing: "1px",
                                }}
                            >
                                Materials
                            </h2>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end">
                            <Button size="sm" color="primary" className="fira" onClick={(e) => setAddModal(true)}>
                                Add new
                            </Button>
                        </Col>
                    </Row>
                    <table className="table table-flush rounded">
                        <thead className="thead-light pp">
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Rate</th>

                            <th scope="col">Action</th>
                        </thead>
                        <tbody>
                            {materials.map((el, index) => (
                                <tr key={index} className="pp">
                                    <th scope="row">{index + 1}</th>
                                    <th scope="row">{el.material_name}</th>
                                    <th scope="row">₹{el.material_rate}</th>

                                    <th scope="row">
                                        <Button size="sm" color="warning" onClick={(e) => onEditBtnHandler(el._id)}>
                                            <Edit size="12px" />
                                        </Button>
                                        <Button size="sm" color="danger" onClick={(e) => onDeleteBtnHandler(el._id)}>
                                            <Trash2 size="12px" />
                                        </Button>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Collapse>
            </Container>
        </Navbar>
    );
};

export default MaterialSidebar;
