import axios from "axios";
class SheetsBackend {
	getAllSheets(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/sheet/only`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}

	getSheetDetail(formData, stoken) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/sheet/get`,
					formData,
					{
						headers: {
							"SESSION-TOKEN": stoken,
						},
					}
				);
				if (res.data.code !== 200) throw res.data;
				resolve(res.data);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteSheetByDate(date) {
		return this.database
			.collection("sheet")
			.where("date", "==", date)
			.get()
			.then((snapshot) => {
				let id = "";
				snapshot.forEach((elem) => {
					id = elem.id;
				});
				let batch = this.database.batch();
				batch.delete(this.database.collection("sheet").doc(id));
				return batch.commit();
			})
			.catch((err) => {
				console.log(err);
			});
	}
}

export let sheetsBackend = new SheetsBackend();
