import React from "react";

import AdminLayout from "../Layout/Component/AdminLayout";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import PreClientComponents from "../Views/Client/component/PreClientComponents";
import { ProtectiveRoute } from "../Views/Auth/ProtectiveRoute";
import PreSheetComponents from "../Views/Sheet/component/PreSheetComponent";
import PreInvoiceComponents from "../Views/Invoice/Component/PreInvoiceComponents";
import NotificationProdvider from "../Common/Notification/NotificationProvider";

export default function App() {
    return (
        <BrowserRouter basename="/">
            <NotificationProdvider>
                <ProtectiveRoute path="/admin" component={AdminLayout} />
                <ProtectiveRoute path="/customer/:id" exact component={PreClientComponents} />
                <Route path="/" exact render={(props) => <Redirect to="/admin" />} />
                <ProtectiveRoute path="/sheet/:id" exact component={PreSheetComponents} />
                <ProtectiveRoute path="/invoice/:id" exact component={PreInvoiceComponents} />
            </NotificationProdvider>
        </BrowserRouter>
    );
}
