import React, { useEffect, useState } from "react";
import { ModalBody, Col, Modal, ModalHeader, Row, FormGroup, Input, ModalFooter, Button, Alert } from "reactstrap";
import { getDateForEntry } from "../../../Common/DateAndTime/getDate";

const GenrateInvoiceNumber = ({ onCancelHandler, onGenerateInvoice, modal }) => {
      const [state, setState] = useState({
            date: getDateForEntry(new Date()),
            invoiceNumber: Number(window.localStorage.getItem("inv_number")),
      });

      useEffect(() => {
            if (modal) {
                  setState({ ...state, invoiceNumber: Number(window.localStorage.getItem("inv_number")) });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [modal]);

      return (
            <Modal isOpen={modal} toggle={onCancelHandler} style={{ zIndex: 10000000000 }}>
                  <ModalHeader className="bg " toggle={onCancelHandler}>
                        <span className="text fs-24 geb">Select Month</span>
                  </ModalHeader>
                  <ModalBody className="bg">
                        <Row>
                              <Col>
                                    <Alert color="warning">
                                          Last invoice number: {window.localStorage.getItem("inv_number")}
                                    </Alert>
                              </Col>
                              <Col xl="12">
                                    <FormGroup>
                                          <label className="form-control-label" htmlFor="input-name">
                                                Invoice Number
                                          </label>
                                          <Input
                                                required
                                                className="form-control-alternative"
                                                placeholder="0"
                                                type="number"
                                                value={state.invoiceNumber}
                                                onChange={(evt) => {
                                                      setState({ ...state, invoiceNumber: evt.target.value });
                                                }}
                                          />
                                    </FormGroup>
                              </Col>
                              <Col xl="12">
                                    <FormGroup>
                                          <label className="form-control-label" htmlFor="input-name">
                                                Date
                                          </label>
                                          <Input
                                                required
                                                className="form-control-alternative"
                                                placeholder="Date"
                                                type="date"
                                                value={state.date}
                                                onChange={(evt) => {
                                                      setState({ ...state, date: evt.target.value });
                                                }}
                                          />
                                    </FormGroup>
                              </Col>
                        </Row>
                  </ModalBody>
                  <ModalFooter className="bg">
                        <Button
                              color="primary"
                              className="btn fira btn-success"
                              onClick={(evt) => {
                                    onGenerateInvoice(state.date, state.invoiceNumber);
                              }}
                        >
                              Add
                        </Button>{" "}
                        <Button color="secondary" className="btn fira btn-warning" onClick={onCancelHandler}>
                              Cancel
                        </Button>
                  </ModalFooter>
            </Modal>
      );
};

export default GenrateInvoiceNumber;
