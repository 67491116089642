import React, { useState, useEffect, useCallback } from "react";
import { Card, CardHeader, Table, Container, Row, Col, CardFooter, Button, Input, InputGroup, InputGroupText } from "reactstrap";
// import Header from "../Common/Header/Header";
import Loader from "../global/Loader/Loader";
import ClientListItem from "./Client/component/ClientListItem";
import SheetListItem from "./Sheet/component/SheetListItem";
import { clientsBackend } from "./Client/client_backend";
import { sheetsBackend } from "./Sheet/sheet_backend";
import { getDateForEntry } from "../Common/DateAndTime/getDate";
import { X } from "react-feather";
import ClientPagination from "./Client/component/ClientPagination";
import SheetPagination from "./Sheet/component/SheetPagination";
const Index = ({ darkModeFlag }) => {
    const [loading, setLoading] = useState(false);

    const [stoken] = useState(window.localStorage.getItem("session_token"));
    const [copyState, setCopyState] = useState({
        customers: [],
        dates: [],
        uid: window.localStorage.getItem("uid"),
    });
    const [state, setState] = useState({
        customers: [],
        dates: [],
        uid: window.localStorage.getItem("uid"),
    });

    const getClients = useCallback(async () => {
        try {
            const formData = new FormData();
            formData.set("uid", state.uid);
            const res = await clientsBackend.getAllClientWithData(formData, stoken);
            const response = await sheetsBackend.getAllSheets(formData, stoken);
            const temp = [...response.data];
            temp.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setCopyState({ ...state, customers: res.data, dates: temp });
            setState({ ...state, customers: res.data, dates: temp });
            setLoading(true);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getClients();
    }, [getClients]);

    const initSearchState = {
        open: false,
        query: "",
    };

    const [searchCustomers, setSeachCustomers] = useState(initSearchState);

    // useEffect(() => {
    //     if (searchCustomers.query.length > 0) {
    //         const queryCustomers = copyState.customers.filter((el) => el.clientFirm.toUpperCase().match(searchCustomers.query.toUpperCase()));
    //         setState({ ...state, customers: queryCustomers });
    //     } else {
    //         setState({ ...state, customers: copyState.customers });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchCustomers.query]);

    const [searchDate, setSeachDate] = useState(initSearchState);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDate.query]);

    const [currentPage, setCurrentPage] = useState(1);
    const [clientPerPage] = useState(15);

    const lastIndex = currentPage * clientPerPage;
    const startIndex = lastIndex - clientPerPage;
    const splittedClients = state.customers.slice(startIndex, lastIndex);

    const [currentSheetPage, setCurrentSheetPage] = useState(1);
    const [sheetPerPage] = useState(15);

    // last index of a page
    const li = currentSheetPage * sheetPerPage;
    // starting index of a page
    const si = li - sheetPerPage;
    // those entries lies in lastIndex and startIndex
    const splittedSheet = state.dates.slice(si, li);

    return loading ? (
        <>
            {/* <Header bg="primary" stats={headerStats} /> */}
            <Container className="pt-md-8 pt-4" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="8">
                        <Card className={darkModeFlag === "false" ? "bg-default shadow" : "shadow"}>
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center geb">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">Added</h6>
                                        <h2
                                            className={darkModeFlag === "false" ? "text-white mb-0 geb fs-24 " : "text-default mb-0 geb fs-24"}
                                            style={{
                                                textTransform: "uppercase",
                                                letterSpacing: "1px",
                                            }}
                                        >
                                            Customers
                                        </h2>
                                    </div>
                                    {!searchCustomers.open ? (
                                        <Button
                                            color="primary"
                                            className="fira shadow mr-3"
                                            onClick={(e) => setSeachCustomers({ ...searchCustomers, open: true })}
                                        >
                                            Search
                                        </Button>
                                    ) : (
                                        <InputGroup className="nn form-control-alternative w-50">
                                            <Input
                                                className="text-primary"
                                                id="input-username"
                                                placeholder="Company or Firm"
                                                type="text"
                                                value={searchCustomers.query}
                                                onChange={(e) => {
                                                    setSeachCustomers({
                                                        ...searchCustomers,
                                                        query: e.target.value,
                                                    });
                                                    if (e.target.value.length > 0) {
                                                        const queryCustomers = copyState.customers.filter((el) =>
                                                            el.clientFirm.toUpperCase().match(e.target.value.toUpperCase())
                                                        );
                                                        setState({ ...state, customers: queryCustomers });
                                                    } else {
                                                        setState({ ...state, customers: copyState.customers });
                                                    }
                                                }}
                                            />
                                            {/* <InputGroupAddon addonType="append"> */}
                                            <InputGroupText>
                                                <X
                                                    className="cp text-danger"
                                                    onClick={(e) => {
                                                        setSeachCustomers({ ...initSearchState });
                                                        setState({ ...state, customers: copyState.customers });
                                                    }}
                                                />
                                            </InputGroupText>
                                            {/* </InputGroupAddon> */}
                                        </InputGroup>
                                    )}
                                </Row>
                            </CardHeader>
                            <Table className={darkModeFlag === "false" ? "pp table-dark table-flush" : "pp table table-flush"} responsive>
                                <thead className={darkModeFlag === "false" ? "thead-dark" : "thead-light"}>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Firm</th>
                                        <th scope="col">Customers</th>
                                        <th scope="col">Contact</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {splittedClients.map((el, index) => (
                                        <ClientListItem key={index + 1} index={index + 1} darkModeFlag={darkModeFlag} client={el} />
                                    ))}
                                </tbody>
                            </Table>
                            <CardFooter className={darkModeFlag === "false" ? "bg-default" : ""}>
                                {/* <div className="col text-warning text-left ml--3">
									<span className="fira bg" style={{ fontSize: "14px" }}>
										Info: Tap on Customer for entries
									</span>
								</div> */}
                                <ClientPagination
                                    clients={state.customers}
                                    bottomText={`${state.customers.length} customers`}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    clientPerPage={clientPerPage}
                                />
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card className={darkModeFlag === "false" ? "pp bg-default shadow" : "pp shadow"}>
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center geb">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">Entry</h6>
                                        <h2
                                            className={darkModeFlag === "false" ? "text-white mb-0 geb fs-24 " : "text-default mb-0 geb fs-24"}
                                            style={{
                                                textTransform: "uppercase",
                                                letterSpacing: "1px",
                                            }}
                                        >
                                            Dates
                                        </h2>
                                    </div>
                                    {!searchDate.open ? (
                                        <Button color="primary" className="fira shadow mr-3" onClick={(e) => setSeachDate({ ...searchDate, open: true })}>
                                            Search
                                        </Button>
                                    ) : (
                                        <InputGroup className="nn form-control-alternative w-100 pl-3 pr-3 my-2">
                                            <Input
                                                className="text-primary"
                                                id="input-username"
                                                placeholder="DD/MM/YYYY"
                                                type="date"
                                                value={searchDate.query}
                                                onChange={(e) => {
                                                    setSeachDate({
                                                        ...searchDate,
                                                        query: e.target.value,
                                                    });
                                                    if (searchDate.query.length > 0) {
                                                        const queryDate = copyState.dates.filter((el) => getDateForEntry(el.date) === searchDate.query);
                                                        setState({ ...state, dates: queryDate });
                                                    } else {
                                                        setState({ ...state, dates: copyState.dates });
                                                    }
                                                }}
                                            />
                                            <InputGroupText>
                                                <X
                                                    className="cp text-danger"
                                                    onClick={(e) => {
                                                        setSeachDate({ ...initSearchState });
                                                        setState({ ...state, dates: copyState.dates });
                                                    }}
                                                />
                                            </InputGroupText>
                                        </InputGroup>
                                    )}
                                </Row>
                            </CardHeader>
                            <Table className={darkModeFlag === "false" ? "table-dark table-flush" : "table table-flush"} responsive>
                                <thead className={darkModeFlag === "false" ? "thead-dark" : "thead-light"}>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Entries</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {splittedSheet.map((el, index) => {
                                        return (
                                            <SheetListItem
                                                key={index}
                                                index={index + 1}
                                                darkModeFlag={darkModeFlag}
                                                date={getDateForEntry(el.date)}
                                                entries={el.entries}
                                                id={el._id}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <CardFooter className={darkModeFlag === "false" ? "bg-default" : ""}>
                                {/* <div className="col text-warning text-left ml--3">
									<span className="fira bg" style={{ fontSize: "14px" }}>
										Info: Tap on Dates for entries
									</span>
								</div> */}
                                <SheetPagination
                                    sheets={state.dates}
                                    bottomText={false}
                                    sheetsPerPage={sheetPerPage}
                                    setCurrentSheetPage={setCurrentSheetPage}
                                    currentSheetPage={currentSheetPage}
                                />
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    ) : (
        <Loader />
    );
};

export default Index;
