import React from "react";
import { Page, Document, StyleSheet, View, Text, Font, Image } from "@react-pdf/renderer";

import InvoiceItemsTable from "./InvoiceItemsTable";

Font.register({
    family: "OSB",
    src: require("../font/OSB.ttf").default,
});
Font.register({
    family: "OSSB",
    src: require("../font/OSSB.ttf").default,
});
Font.register({
    family: "OSM",
    src: require("../font/OSM.ttf").default,
});

const styles = StyleSheet.create({
    page: {
        fontSize: 11,
        paddingTop: 35,
        paddingBottom: 35,
        paddingLeft: 30,
        paddingRight: 30,
        lineHeight: 1.5,
        flexDirection: "column",
    },

    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    col: {
        width: "5pt",
    },
    titleContainer: {
        flexDirection: "row",
        width: "100%",
        marginTop: 0,
        justifyContent: "center",
    },
    reportTitle: {
        color: "#61dafb",
        letterSpacing: 4,
        fontSize: 25,
        textAlign: "center",
        textTransform: "uppercase",
    },
    headerContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    billTo: {
        paddingBottom: 3,
    },
    logo: {
        marginLeft: "auto",
        height: "100px",
    },
    bold: {
        fontSize: 13,
        fontWeight: "bold",
    },
    col1: {
        width: "80%",
    },
    colorx: {
        color: "#7f7f7f",
    },
    logoCont: {
        width: "90%",
        display: "flex",
        justifyContent: "flex-end",
    },
    reportTitle2: {
        fontSize: 12,
        fontFamily: "OSB",
        textAlign: "center",
        textTransform: "uppercase",
    },
    rowBillTo: {
        display: "flex",
        flexDirection: "row",
        marginTop: 5,
        borderTopColor: "#7f7f7f",
        borderTopWidth: 1,
        paddingTop: 6,
    },
    text: {
        fontFamily: "OSM",
        color: "#7f7f7f",
        fontSize: 10,
        textTransform: "uppercase",
        // fontWeight: "bold",
    },
    colBillTo: {
        width: "80%",
    },
    colxBillTo: {
        display: "flex",
        flexDirection: "column",
    },
});

const Invoice = ({ invoice }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.titleContainer}>
                    <Text style={{ fontSize: 10, textTransform: "uppercase", fontFamily: "OSB" }}>Tax Invoice</Text>
                </View>
                <View style={styles.headerContainer}>
                    <View style={styles.col1}>
                        <Text style={{ fontSize: 10, textTransform: "uppercase", fontFamily: "OSB" }}>{invoice.firm}</Text>
                        <Text style={styles.text}>{invoice.address}</Text>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                            Phone:<Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.phone}</Text>
                        </Text>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                            Email:<Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.email}</Text>
                        </Text>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                            GSTIN:<Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.gst}</Text>
                        </Text>
                        {invoice.bank_name && invoice.account && (
                            <Text style={{ fontSize: 8, textTransform: "uppercase", fontFamily: "OSB", marginTop: 5 }}>BANK DETAILS:</Text>
                        )}
                        {invoice.bank_name && (
                            <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                                Name:<Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.bank_name}</Text>
                            </Text>
                        )}
                        {invoice.account && (
                            <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                                Account No:{" "}
                                <Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.account}</Text>
                            </Text>
                        )}
                        {invoice.ifsc && (
                            <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                                IFSC: <Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}> {invoice.ifsc}</Text>
                            </Text>
                        )}
                    </View>
                    <View style={styles.logoCont}>
                        <Image
                            style={styles.logo}
                            src={{
                                uri: invoice.url
                                    ? `${process.env.REACT_APP_API_URL}/uploads/${invoice.url}`
                                    : "http://thedigicard.in/invoicemg/api/uploads/1601556728511_download.png",
                            }}
                        />
                    </View>
                </View>
                <View style={styles.rowBillTo}>
                    <View style={styles.colBillTo}>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>Invoice To:</Text>
                        <Text style={{ fontSize: 10, textTransform: "uppercase", fontFamily: "OSB" }}>{invoice.clientFirm}</Text>
                        <Text style={styles.text}>{invoice.clientAddress}</Text>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                            Phone: <Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}>{invoice.clientPhone}</Text>
                        </Text>
                        <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                            GSTIN: <Text style={{ fontSize: 10, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSM" }}>{invoice.clientGST}</Text>
                        </Text>
                    </View>
                    <View style={styles.colxBillTo}>
                        <View>
                            <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                                Invoice No:
                                <Text style={{ fontSize: 10, color: "#000000", textTransform: "uppercase", fontFamily: "OSB" }}> {invoice.invoiceNumber} </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 8, color: "#7f7f7f", textTransform: "uppercase", fontFamily: "OSB" }}>
                                Date:<Text style={{ fontSize: 10, color: "#000000", textTransform: "uppercase", fontFamily: "OSB" }}> {invoice.date} </Text>
                            </Text>
                        </View>
                    </View>
                </View>
                <InvoiceItemsTable invoice={invoice} />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle2}>Thank you for your business</Text>
                </View>
            </Page>
        </Document>
    );
};

export default Invoice;
