import React, { useEffect, useState } from "react";

import AuthNavbar from "../../Common/Navbars/AuthNavbar";
import Login from "../../Views/Auth/components/Login";
import { Container, Row } from "reactstrap";
// import Register from "../../Views/Auth/components/Register";

const Auth = () => {
	useEffect(() => {
		document.body.classList.add("bg-default");
	}, []);

	const [view, setView] = useState("LOGIN");

	return (
		<div style={{ height: "100vh" }}>
			<div className="main-content h-100 bg-auth">
				<AuthNavbar />
				<div className="header   py-7 py-lg-8"></div>
				<Container className="mt--8 pb-5 ">
					<Row className="justify-content-center">
						{view === "LOGIN" ? <Login setView={setView} /> : null}
						{/* {view === "REGISTER" ? <Register setView={setView} /> : null} */}
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Auth;
