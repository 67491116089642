import axios from "axios";
import { saveAs } from "file-saver";
const HEADER = {
    headers: {
        "SESSION-TOKEN": window.localStorage.getItem("session_token"),
    },
};
class ClientsBackend {
    /**
     * @param {string} entry
     * */

    addNewClient(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/add`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getOnlyClients(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/only`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getAllClientWithData(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/getAll`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    editClientDetail(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/update`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }
    deleteClient(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/remove`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getClientDetail(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/get`, formData, HEADER);
                // const res = await axios.post(`http://localhost:5000/client/get`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }
    saveInvoice(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/invoice/save`, formData, HEADER);
                // const res = await axios.post(`http://localhost:5000/invoice/save`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    batchUpdate(formData) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/client/batchUpdate`, formData, HEADER);
                // const res = await axios.post(`http://localhost:5000/client/batchUpdate`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getXLXS(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/stats/exports/${data.uid}/${data.mode}`, HEADER);
                // const res = await axios.post(`http://localhost:5000/client/batchUpdate`, formData, HEADER);
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }
    async downloadXLXS(fileUrl) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/stats/download/${fileUrl}`, {
            headers: {
                "SESSION-TOKEN": window.localStorage.getItem("session_token"),
            },
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
}

export let clientsBackend = new ClientsBackend();
