import React from "react";
/* eslint-disable */
import { Link } from "react-router-dom";
import { getDate } from "../../../Common/DateAndTime/getDate";
const SheetListItem = ({ date, index, entries, id }) => {
	let link = "/sheet/" + id;
	return (
		<tr>
			<th scope="row">{index}</th>
			<th scope="row">
				<Link className={"text-primary"} to={link}>
					{getDate(date)}
				</Link>
			</th>
			<th scope="row">{entries}</th>
		</tr>
	);
};

export default SheetListItem;
