import React, { useState, useEffect, useCallback } from "react";
import LiteHeader from "../../../Common/Header/LiteHeader";
import {
	Card,
	CardHeader,
	Row,
	Col,
	Table,
	Container,
	Button,
	CardFooter,
} from "reactstrap";
import DeleteClientModal from "./DeleteClientModal";
import { clientsBackend } from "../client_backend";
import AddClientModal from "./AddClientModal";
import { Trash2, Edit } from "react-feather";
import { Link } from "react-router-dom";
import EditClientModal from "./EditClientModal";

const Client = ({ darkModeFlag }) => {
	const [uid] = useState(window.localStorage.getItem("uid"));
	const [form, setForm] = useState({
		_id: "",
		clientName: "",
		clientFirm: "",
		clientNumber: "",
		clientGST: "",
		clientAddress: "",
		status: false,
		error: "",
	});

	const initialForm = {
		_id: "",
		clientName: "",
		clientFirm: "",
		clientNumber: "",
		clientGST: "",
		clientAddress: "",
		status: false,
		error: "",
	};

	const [deleteModal, setDeleteModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	const [state, setState] = useState([]);

	const getClients = useCallback(async () => {
		try {
			const formData = new FormData();
			formData.set("uid", uid);
			const res = await clientsBackend.getOnlyClients(
				formData,
				window.localStorage.getItem("uid")
			);
			setState(res.data);
			return "";
		} catch (error) {
			console.log(error);
		}
	}, [uid]);

	useEffect(() => {
		getClients();
	}, [getClients]);

	const onCancelHandler = () => {
		setAddModal(false);
		setDeleteModal(false);
		setEditModal(false);
		setForm(initialForm);
	};

	const onDeleteHandler = async () => {
		try {
			const formData = new FormData();
			formData.set("client_id", form._id);
			const res = await clientsBackend.deleteClient(
				formData,
				window.localStorage.getItem("session_token")
			);
			if (res.status) {
				const index = state.findIndex((el) => el._id === form._id);
				const temp = state;
				temp.splice(index, 1);
				setState([...temp]);
				setDeleteModal(false);
			} else {
				alert("Something went wrong!");
			}
		} catch (error) {
			console.log("er", error);
		}
	};

	const onChangeValue = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	const onAddClientHandler = async (e) => {
		try {
			const formData = new FormData();
			formData.set("uid", uid);
			formData.set("client_name", form.clientName);
			formData.set("client_firm", form.clientFirm);
			formData.set("client_phone", form.clientNumber);
			formData.set("client_address", form.clientAddress);
			formData.set("client_gst", form.clientGST);

			const res = await clientsBackend.addNewClient(
				formData,
				window.localStorage.getItem("session_token")
			);
			setForm({ ...form, status: true });
			setState([...state, res.data]);
			setTimeout(() => {
				setAddModal(false);
				setForm(initialForm);
			}, 1500);
		} catch (error) {
			if (error.error.client_phone)
				return setForm({
					...form,
					error: "Invalid contact number. It should be 10 digit",
				});
			if (error.error.client_gst)
				return setForm({
					...form,
					error: "Invalid GST number or customer is already added with this.",
				});
		}
	};

	const onCustomerEdit = (id) => {
		const customer = state.filter((el) => el._id === id);
		setForm({
			...form,
			_id: id,
			clientName: customer[0].clientName,
			clientFirm: customer[0].clientFirm,
			clientNumber: customer[0].clientPhone,
			clientGST: customer[0].clientGST,
			clientAddress: customer[0].clientAddress,
		});
		setEditModal(true);
	};

	const onEditClientHandler = async () => {
		try {
			const formData = new FormData();
			formData.set("client_id", form._id);
			formData.set("client_name", form.clientName);
			formData.set("client_firm", form.clientFirm);
			formData.set("client_phone", form.clientNumber);
			formData.set("client_gst", form.clientGST);
			formData.set("client_address", form.clientAddress);
			const res = await clientsBackend.editClientDetail(
				formData,
				window.localStorage.getItem("session_token")
			);
			const index = state.findIndex((el) => el._id === form._id);
			const temp = state;
			temp.splice(index, 1);
			temp.splice(index, 0, res.data);
			setState([...temp]);
			setForm({ ...form, status: true });
			setTimeout(() => {
				setEditModal(false);
				setForm(initialForm);
			}, 1500);
		} catch (error) {
			console.log("HERE :", error);
		}
	};

	const onCustomerDelete = async (id) => {
		setForm({ ...form, _id: id });
		setDeleteModal(true);
	};

	return (
		<>
			<LiteHeader bg={"primary"} />
			<Container fluid className="mt--8">
				<Row>
					<Col className="order-xl-1 mt-2 mx-auto d-flex" xl="10" responsive>
						<Card
							className={
								darkModeFlag === "false"
									? "bg-default shadow w-100"
									: "shadow w-100"
							}
						>
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<div className="col">
										<h6 className="text-uppercase text-muted ls-1 mb-1">
											Added
										</h6>
										<h2
											className={
												darkModeFlag === "false"
													? "text-white mb-0 geb fs-24 "
													: "text-default mb-0 geb fs-24"
											}
											style={{
												textTransform: "uppercase",
												letterSpacing: "1px",
											}}
										>
											Customers
										</h2>
									</div>
									<div className="d-flex">
										<Button
											color="primary"
											className="fira mr-2"
											onClick={(e) => setAddModal(true)}
										>
											Add new
										</Button>
									</div>
								</Row>
							</CardHeader>
							<Table
								className={
									darkModeFlag === "false"
										? "align-items-center table-dark table-flush"
										: "align-items-center table table-flush"
								}
								responsive
							>
								<thead
									className={
										darkModeFlag === "false"
											? " pp thead-dark "
											: "  pp thead-light"
									}
								>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Client</th>
										<th scope="col">Firm</th>
										<th scope="col">Phone</th>

										<th scope="col">GST</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{state.map((el, index) => (
										<tr key={index} className="pp-l">
											<th scope="row">{index + 1}</th>

											<th scope="row">
												<Link to={`/customer/${el._id}`}>{el.clientName}</Link>
											</th>
											<th scope="row">{el.clientFirm}</th>
											<th scope="row">{el.clientPhone}</th>
											<th scope="row">{el.clientGST}</th>
											<th scope="row">
												<Button
													size="sm"
													color="warning"
													onClick={(e) => onCustomerEdit(el._id)}
												>
													<Edit size="13" />
												</Button>

												<Button
													size="sm"
													color="danger"
													onClick={(e) => onCustomerDelete(el._id)}
												>
													<Trash2 size="13" />
												</Button>
											</th>
										</tr>
									))}
								</tbody>
							</Table>
							<CardFooter
								className={darkModeFlag === "false" ? "bg-default" : ""}
							>
								<div className=" text-sm text-warning fira">
									Note: Deleting this will remove all entries and materials
								</div>
								<div className="text-sm   mt-2  text-info fira">
									Info: Please refresh after delete
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
			<AddClientModal
				darkModeFlag={darkModeFlag}
				modal={addModal}
				state={form}
				onChangeValue={onChangeValue}
				onCancelHandler={onCancelHandler}
				handleSubmit={onAddClientHandler}
			/>
			<EditClientModal
				darkModeFlag={darkModeFlag}
				modal={editModal}
				state={form}
				onChangeValue={onChangeValue}
				onCancelHandler={onCancelHandler}
				handleSubmit={onEditClientHandler}
			/>

			<DeleteClientModal
				modal={deleteModal}
				onDeleteCancelHandler={onCancelHandler}
				onDeleteHandler={onDeleteHandler}
			/>
		</>
	);
};

export default Client;
