import React from "react";

import { Route } from "react-router-dom";
import AuthLayout from "../../Layout/Component/AuthLayout";

export const ProtectiveRoute = ({ component: Component, ...rest }) => {
	const [darkMode] = React.useState(
		window.localStorage.getItem("mode")
			? window.localStorage.getItem("mode")
			: "false"
	);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (window.localStorage.getItem("uid")) {
					return (
						<Component
							// email={window.localStorage.getItem("email")}
							uid={window.localStorage.getItem("uid")}
							darkModeFlag={darkMode}
							{...props}
						/>
					);
				} else {
					return <AuthLayout />;
				}
			}}
		/>
	);
};
