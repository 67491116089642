import React, { useState, useEffect, useCallback } from "react";
// import Liteheader from "../../../Common/Header/LiteHeader";
import ClientComponentPage from "./ClientComponentPage";
import Footer from "../../../Common/Footers/AdminFooter";
import useCustomSnackbar from "../../../Common/Notification/CustomSnackbar";

import { Row, Container, Col } from "reactstrap";
import { Menu } from "react-feather";
import ModalAddEntry from "./ModalAddEntry";
import { getDate, getDateForEntry } from "../../../Common/DateAndTime/getDate";
import { entryBackend } from "../../ClientEntry/clientEntry_backend";
import ModalEntryDelete from "./ModalDelete";
import * as xlsx from "xlsx";
import { RoundOff } from "../../../Common/DateAndTime/RoundOff";
import { clientsBackend } from "../client_backend";

const ClientComponents = ({ uid, darkModeFlag, user, customer, setOpen, cid, setCustomer }) => {
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [entryId, setEntryId] = useState("");
    const [due, setDue] = useState(0);

    const mSnackbar = useCustomSnackbar();

    const onCancelHandler = () => {
        setForm(initialState);
        setAddModal(false);
        setEditModal(false);
        setDeleteModal(false);
        setEntryId("");
    };

    const onTapEdit = (id) => {
        setEntryId(id);
        const row = customer.entries.filter((el) => el._id === id)[0];
        setForm({
            ...form,
            advance: row.advance,
            amount: row.amount,
            cgst: row.cgst,
            sgst: row.sgst,
            length: Number(row.length),
            width: Number(row.width),
            description: row.description,
            has_issued: row.has_issued,
            material: row.material,
            qty: row.qty,
            rate: row.rate,
            total: row.total,
            date: getDateForEntry(row.date),
        });
        setEditModal(true);
    };

    const editEntry = async () => {
        try {
            const formData = new FormData();
            formData.set("uid", uid);
            formData.set("entry_id", entryId);
            formData.set("client_id", cid);
            formData.set("date", new Date(form.date));
            formData.set("material", form.material);
            formData.set("description", form.description);
            formData.set("item_length", form.length);
            formData.set("item_width", form.width);
            formData.set("amount", form.amount);
            formData.set("qty", form.qty);
            formData.set("rate", form.rate);
            formData.set("igst", form.igst);
            formData.set("cgst", form.cgst);
            formData.set("sgst", form.sgst);
            formData.set("total", form.total);
            formData.set("advance", form.advance);

            const res = await entryBackend.editEntry(formData, window.localStorage.getItem("session_token"));
            const entries = customer.entries;
            const index = entries.findIndex((el) => el._id === entryId);
            entries.splice(index, 1);
            entries.splice(index, 0, res.data);
            setCustomer({ ...customer, entries: entries });
            setEditModal(false);
        } catch (err) {
            console.log("err :", err);
        }
    };

    const onTapDeleteEntry = (id) => {
        setEntryId(id);
        setDeleteModal(true);
    };

    const deleteEntry = async () => {
        try {
            const indx = customer.entries.findIndex((el) => el._id === entryId);
            const formData = new FormData();
            formData.set("entry_id", entryId);
            formData.set("client_id", cid);
            formData.set("date", customer.entries[indx].date);
            await entryBackend.deleteEntry(formData, window.localStorage.getItem("session_token"));
            const entries = customer.entries;
            const index = entries.findIndex((el) => el._id === entryId);
            entries.splice(index, 1);
            setCustomer({ ...customer, entries: entries });
            setDeleteModal(false);
        } catch (err) {
            console.log("err :", err);
        }
    };

    const addNewEntry = async () => {
        try {
            const formData = new FormData();
            formData.set("uid", uid);
            formData.set("client_id", cid);
            formData.set("date", new Date(form.date));
            formData.set("material", form.material);
            formData.set("description", form.description);
            formData.set("item_length", form.length);
            formData.set("item_width", form.width);
            formData.set("amount", form.amount);
            formData.set("qty", form.qty);
            formData.set("rate", form.rate);
            formData.set("igst", form.igst);
            formData.set("cgst", form.cgst);
            formData.set("sgst", form.sgst);
            formData.set("total", form.total);
            formData.set("advance", form.advance);

            const res = await entryBackend.addNewEntry(formData, window.localStorage.getItem("session_token"));
            const entries = customer.entries;
            const temp = [...entries, res.data];
            setCustomer({ ...customer, entries: temp });
            setAddModal(false);
        } catch (err) {
            console.log("err :", err);
        }
    };

    const onAddEntryHandler = () => {
        setAddModal(true);
    };

    const [form, setForm] = useState({
        date: new Date(),
        description: "",
        material: "",
        rate: 0,
        qty: 1,
        length: 0,
        width: 0,
        amount: 0,
        cgst: 0,
        igst: 0,
        sgst: 0,
        total: 0,
        advance: 0,
        has_issued: false,
    });

    const dueCount = useCallback(() => {
        const temp = customer.entries.filter((el) => el.total > 0 && !el.has_issued);
        let count = 0;
        for (let i = 0; i < temp.length; i++) {
            count = count + temp[i].total;
        }
        setDue(count);
    }, [customer.entries]);

    const setDateInit = useCallback(() => {
        setForm({
            ...form,
            date: getDateForEntry(new Date()),
        });
        //!custome rule
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setForm]);

    useEffect(() => {
        dueCount();
        setDateInit();
    }, [dueCount, setDateInit]);

    const onChange = (e) => {
        console.log("ok");
        setForm({ ...form });
        if (e.target.name === "date") {
            setForm({ ...form, date: e.target.value });
            return;
        }
        if (e.target.name === "material") {
            const temp = customer.materials.filter((el) => el.material_name === e.target.value);
            const rate = temp[0].material_rate;
            const amount = form.qty * form.length * form.width * form.rate;
            const total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - form.advance;
            setForm({
                ...form,
                material: temp[0].material_name,
                rate: rate,
                amount: RoundOff(amount),
                total: RoundOff(total),
            });
            return;
        }
        if (e.target.name === "length") {
            const amount = form.qty * form.width * e.target.value * form.rate;
            const total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - form.advance;
            setForm({ ...form, length: e.target.value, amount: RoundOff(amount), total: RoundOff(total) });
            return;
        }
        if (e.target.name === "width") {
            const amount = form.qty * form.length * e.target.value * form.rate;
            const total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - form.advance;
            setForm({ ...form, width: e.target.value, amount: RoundOff(amount), total: RoundOff(total) });
            return;
        }
        if (e.target.name === "qty") {
            const amount = e.target.value * form.length * form.width * form.rate;
            const total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - form.advance;
            setForm({ ...form, qty: e.target.value, amount: RoundOff(amount), total: RoundOff(total) });
            return;
        }
        if (e.target.name === "rate") {
            const amount = form.qty * form.length * form.width * e.target.value;
            const total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - form.advance;
            setForm({ ...form, rate: e.target.value, amount: RoundOff(amount), total: RoundOff(total) });
            return;
        }
        if (e.target.name === "igst") {
            const amount = form.qty * form.length * form.width * form.rate;
            const total = amount * (1 + form.igst / 100) - form.advance;
            setForm({ ...form, igst: e.target.value, total: RoundOff(total) });
            return;
        }
        if (e.target.name === "cgst") {
            const amount = form.qty * form.length * form.width * form.rate;
            const total = amount * (1 + e.target.value / 100 + e.target.value / 100 + form.igst / 100) - form.advance;
            setForm({
                ...form,
                cgst: e.target.value,
                sgst: e.target.value,
                total: RoundOff(total),
            });
            return;
        }
        if (e.target.name === "advance") {
            const advance = e.target.value;
            const amount = form.qty * form.length * form.width * form.rate;
            let total = amount * (1 + form.cgst / 100 + form.sgst / 100 + form.igst / 100) - advance;
            if (total < 0) {
                total = 0;
            }
            setForm({ ...form, advance: e.target.value, total: RoundOff(total) });
            return;
        }
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const downloadXLSX = async (uid, mode) => {
        // PLAIN SIMPLE XSLX sheet
        // let data = "date,material,description,length,width,qty,rate,amount,cgst,sgst,igst,total,advance,has_issued";
        // data = [data.split(",")];
        // for (let entry of entries) {
        //     const entryKeys = [
        //         "date",
        //         "material",
        //         "description",
        //         "length",
        //         "width",
        //         "qty",
        //         "rate",
        //         "amount",
        //         "cgst",
        //         "sgst",
        //         "igst",
        //         "total",
        //         "advance",
        //         "has_issued",
        //     ];
        //     data.push(
        //         Array.from(
        //             entryKeys.map((key) => {
        //                 if (key === "date") {
        //                     return getDate(entry[key]);
        //                 } else return entry[key];
        //             })
        //         )
        //     );
        // }
        // let ws_name = title;
        // let wb = xlsx.utils.book_new();
        // let ws = xlsx.utils.aoa_to_sheet(data);
        // xlsx.utils.book_append_sheet(wb, ws, ws_name);
        // xlsx.writeFile(wb, `${title}`);
        try {
            const data = {
                uid: uid,
                mode: mode,
            };
            const res = await clientsBackend.getXLXS(data);
            mSnackbar({ variant: true, message: "Wait until your file downloads!", head: "Successfully Exported!" });
            setTimeout(async () => {
                await clientsBackend.downloadXLXS(res.message.split("/")[2]);
            }, 2000);
        } catch (error) {
            return mSnackbar({ variant: false, message: error.message, head: "Something Went Wrong!" });
        }
    };

    const getClientName = (name) => {
        let arr = name.split(" ");
        if (arr.length > 2) {
            return arr[0].toUpperCase().charAt(0) + arr[1].toUpperCase().charAt(0) + arr[2].toUpperCase().charAt(0);
        }
        return arr[0].toUpperCase().charAt(0) + arr[1].toUpperCase().charAt(0);
    };

    const initialState = {
        date: new Date(),
        description: "",
        material: "",
        rate: 0,
        qty: 1,
        length: 0,
        width: 0,
        amount: 0,
        cgst: 0,
        igst: 0,
        sgst: 0,
        total: 0,
        advance: 0,
        has_issued: false,
    };

    return (
        <>
            {/* <Liteheader bg="primary" /> */}
            <Container
                fluid
                className={darkModeFlag === "false" ? "" : "bg-gradient-primary"}
                // style={{ marginTop: -15 + "rem" }}
                style={{ paddingTop: "20px" }}
            >
                <Row>
                    <Col xl="12" className="d-flex ai-center">
                        <span
                            onClick={(e) =>
                                setOpen((prev) => {
                                    return { ...prev, materialSidebar: true };
                                })
                            }
                            className="text-white"
                        >
                            <Menu className="cp" size="32" />
                        </span>
                        <span className="text-white geb fs-24" style={{ marginLeft: "30px" }}>
                            Total Due : &#8377;{due}
                        </span>
                    </Col>
                </Row>
                <ClientComponentPage
                    darkModeFlag={darkModeFlag}
                    sheet={customer}
                    customer={customer}
                    setOpen={setOpen}
                    setCustomer={setCustomer}
                    entries={customer.entries}
                    uid={uid}
                    user={user}
                    onAddEntryHandler={onAddEntryHandler}
                    onTapEdit={onTapEdit}
                    onTapDeleteEntry={onTapDeleteEntry}
                    onTapDownload={downloadXLSX}
                />
                <ModalAddEntry
                    modal={addModal}
                    onCancelHandler={onCancelHandler}
                    onSubmitHandler={addNewEntry}
                    state={form}
                    customer={customer}
                    onChange={onChange}
                />
                <ModalAddEntry
                    modal={editModal}
                    onCancelHandler={onCancelHandler}
                    onSubmitHandler={editEntry}
                    state={form}
                    customer={customer}
                    onChange={onChange}
                />
                <ModalEntryDelete modal={deleteModal} onCancelHandler={onCancelHandler} onSubmitHandler={deleteEntry} />
            </Container>
            <Container className={darkModeFlag === "false" ? "" : "bg-gradient-primary"} fluid>
                <Footer darkModeFlag={darkModeFlag === "false" ? "false" : "true"} />
            </Container>
        </>
    );
};

export default ClientComponents;
