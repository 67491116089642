const axios = require("axios");
class UserBackend {
    getUserInfo(formData, stoken) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/userinfo/get`, formData, {
                    headers: {
                        "SESSION-TOKEN": stoken,
                    },
                });
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }
    addUserInfo(formData, stoken) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/userinfo/add`, formData, {
                    headers: {
                        "SESSION-TOKEN": stoken,
                    },
                });
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    addUserImage(formData, setProgressCount, stoken) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/userinfo/upload`,
                    formData,
                    {
                        headers: {
                            "SESSION-TOKEN": stoken,
                        },
                    },
                    {
                        onUploadProgress: (progressEvent) => {
                            setProgressCount(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        },
                    }
                );
                if (res.data.code !== 200) throw res.data;
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export let userBackend = new UserBackend();
