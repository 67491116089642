import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";

const ClientPagination = ({ clients, clientPerPage, setCurrentPage, currentPage, bottomText, filterSwitch, onDoneEntriesSelection, onSaveInvoice, ready }) => {
    let [pages, setPages] = useState([]);
    let temp = [];
    for (let page = 1; page <= Math.ceil(clients.length / clientPerPage); page++) {
        if (page < 4) {
            temp.push(page);
        }
    }

    useEffect(() => {
        setPages(temp);
        if (Math.ceil(clients.length / clientPerPage) > 3) {
            if (currentPage === Math.ceil(clients.length / clientPerPage)) {
                return setPages([currentPage - 2, currentPage - 1, currentPage]);
            }
            if (currentPage === 1) {
                return setPages([currentPage, currentPage + 1, currentPage + 2]);
            }
            if (currentPage > 2 && currentPage <= Math.ceil(clients.length / clientPerPage)) {
                return setPages([currentPage - 1, currentPage, currentPage + 1]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    return (
        <nav aria-label="..." className="row">
            <div className="col-xl-2 d-flex GEB align-items-center">
                {filterSwitch && (
                    <>
                        <Button onClick={onDoneEntriesSelection} color="primary" className="fira">
                            Next
                        </Button>
                        {ready && (
                            <Button onClick={onSaveInvoice} color="danger" className="fira">
                                Save This Invoice
                            </Button>
                        )}
                    </>
                )}
                {bottomText && <div className="heading">{bottomText}</div>}
            </div>
            <Pagination className="col-xl-10 pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                <PaginationItem className={currentPage === 1 ? "disabled" : ""}>
                    <PaginationLink onClick={(e) => setCurrentPage(currentPage - 1)}>
                        <ChevronLeft size="15" />
                        <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {pages.map((page, index) => {
                    return (
                        <PaginationItem key={index} className={currentPage === page ? "active" : ""} onClick={(e) => setCurrentPage(page)}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>
                    );
                })}

                <PaginationItem className={currentPage === pages[pages.length - 1] ? "disabled" : "text-primary"}>
                    <PaginationLink onClick={(e) => setCurrentPage(currentPage + 1)}>
                        <ChevronRight size="15" />
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </nav>
    );
};

export default ClientPagination;
