import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	FormGroup,
	Input,
	ModalFooter,
	Button,
	Form,
} from "reactstrap";
const ModalAddEntry = ({
	modal,
	onSubmitHandler,
	onCancelHandler,
	state,
	customer,
	onChange,
}) => {
	return (
		<Modal size="lg" isOpen={modal} toggle={onCancelHandler}>
			<ModalHeader className="bg " toggle={onCancelHandler}>
				<h3 className="text-default">Update Entry</h3>
			</ModalHeader>
			<ModalBody className="bg">
				<Form>
					<h6 className="heading-small text-muted mb-4">
						Personal information
					</h6>
					<Row>
						<Col lg="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12 "
									htmlFor="input-name"
								>
									Date
								</label>
								<Input
									required
									className="form-control-alternative nn"
									placeholder="Name"
									type="date"
									name="date"
									value={state.date}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
						<Col lg="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-email"
								>
									Material/Product
								</label>
								<select
									className="form-control nn form-control-alternative "
									name="material"
									value={state.material}
									onChange={(e) => onChange(e)}
								>
									<option>None</option>
									{customer.materials.map((el, index) => (
										<option value={el.material_name} key={index}>
											{el.material_name}
										</option>
									))}
								</select>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-first-name"
								>
									Description
								</label>
								<Input
									required
									className="form-control-alternative nn"
									id="input-first-name"
									placeholder="Description"
									type="text"
									name="description"
									value={state.description}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="2">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Length
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="number"
									name="length"
									value={state.length}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
						<Col md="2">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Width
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="number"
									name="width"
									value={state.width}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
						<Col md="2">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Qty
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="number"
									name="qty"
									value={state.qty}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>

						<Col lg="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-city"
								>
									Rate
								</label>
								<Input
									className="form-control-alternative nn"
									defaultValue=""
									id="input-city"
									placeholder="0"
									type="number"
									name="rate"
									value={state.rate}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Amount
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="number"
									name="amount"
									value={state.amount}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
						{!state.cgst || !state.sgst ? (
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-address"
									>
										IGST
									</label>
									<Input
										className="form-control-alternative nn"
										id="input-address"
										placeholder="0"
										type="number"
										name="igst"
										value={state.igst}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
						) : null}
					</Row>
					{!state.igst ? (
						<Row>
							<Col md="6">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-address"
									>
										CGST
									</label>
									<Input
										className="form-control-alternative nn"
										id="input-address"
										placeholder="0"
										type="number"
										name="cgst"
										value={state.cgst}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>

							<Col lg="6">
								<FormGroup>
									<label
										className="form-control-label pp fs-12"
										htmlFor="input-city"
									>
										SGST
									</label>
									<Input
										className="form-control-alternative nn"
										defaultValue=""
										id="input-city"
										placeholder="0"
										type="number"
										name="sgst"
										value={state.sgst}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
						</Row>
					) : null}
					<Row>
						<Col md="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Total
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="number"
									name="total"
									value={state.total}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<label
									className="form-control-label pp fs-12"
									htmlFor="input-address"
								>
									Advance
								</label>
								<Input
									className="form-control-alternative nn"
									id="input-address"
									placeholder="0"
									type="text"
									name="advance"
									value={state.advance}
									onChange={(e) => onChange(e)}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</ModalBody>
			<ModalFooter className="bg">
				<Button
					color="primary"
					className="btn btn-danger"
					onClick={(e) => onCancelHandler()}
				>
					Cancel
				</Button>
				<Button
					color="primary"
					className="btn btn-success"
					onClick={onSubmitHandler}
				>
					Done
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalAddEntry;
