import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	Button,
	ModalFooter,
	FormGroup,
} from "reactstrap";

const ModalCopyProducts = ({
	modal,
	onCancelHandler,
	onCopyHandler,
	customers,
	optCustomers,
	onChangeTo,
	state,
	onChangeFrom,
	status,
}) => {
	return (
		<Modal isOpen={modal} size="lg" toggle={onCancelHandler}>
			<ModalHeader className="bg" toggle={onCancelHandler}>
				<span className="text-default geb fs-18">Copy Products</span>
			</ModalHeader>
			<ModalBody className="bg">
				{status ? (
					<div className="alert alert-success">Succefully copied</div>
				) : null}
				<Row>
					<Col lg="6">
						<FormGroup>
							<label className="form-control-label pp fs-12">From</label>
							<select
								className="form-control nn form-control-alternative "
								onChange={(e) => onChangeTo(e.target.value)}
							>
								<option value={false}>Select One</option>
								{customers.map((el, index) => (
									<option value={el._id} key={index}>
										{el.clientName}
									</option>
								))}
							</select>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
							<label className="form-control-label pp fs-12">To</label>
							<select
								className="form-control nn form-control-alternative "
								onChange={(e) => onChangeFrom(e.target.value)}
							>
								<option value={false}>Select One</option>
								{optCustomers.map((el, index) => (
									<option value={el._id} key={index}>
										{el.clientName}
									</option>
								))}
							</select>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter className="bg">
				<Button color="primary" className="fira" onClick={onCopyHandler}>
					Copy
				</Button>
				<Button color="danger" className="fira" onClick={onCancelHandler}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalCopyProducts;
