import React from "react";
import { Link } from "react-router-dom";

const SidebarItems = ({ Icon, view, active, route, onTap, index }) => {
    return (
        <Link to={route} onClick={(e) => onTap(index)}>
            <div
                className={
                    active
                        ? window.innerWidth > 700
                            ? "text-default my-2 nav-link active cp bg-primary-fade"
                            : "text-default my-2 nav-link active cp bg-primary rounded"
                        : "text-default my-2 nav-link cp hover-eff"
                }
            >
                <span className="mr-2">
                    <Icon size="18" style={{ verticalAlign: "text-bottom" }} />
                </span>
                <span className="nn-bb">{view}</span>
            </div>
        </Link>
    );
};

export default SidebarItems;
