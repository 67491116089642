import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	ModalFooter,
	Button,
} from "reactstrap";

const DeleteClientModal = ({
	modal,
	onDeleteHandler,
	onDeleteCancelHandler,
}) => {
	return (
		<Modal isOpen={modal} toggle={onDeleteCancelHandler}>
			<ModalHeader className="bg-default" toggle={onDeleteCancelHandler}>
				<h3 className="text-white">Edit Details</h3>
			</ModalHeader>
			<ModalBody className="bg-default">
				<Row>
					<Col>
						<h4 className="text-white">Are you sure?</h4>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter className="bg-default">
				<Button
					color="primary"
					className="btn btn-Danger"
					onClick={onDeleteHandler}
				>
					Delete
				</Button>
				<Button
					color="secondary"
					className="btn btn-primary"
					onClick={onDeleteCancelHandler}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteClientModal;
