import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    // Row,
    Col,
} from "reactstrap";
import { Mail, Lock, AlertOctagon } from "react-feather";
import { authBackend } from "../auth_backend";
const Login = ({ setView }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const login = async () => {
        if (!email || !email.includes("@")) return setError("Invalid email.");
        if (!password) return setError("Invalid password.");

        const formData = new FormData();
        formData.set("email", email);
        formData.set("password", password);
        try {
            const res = await authBackend.loginWithEmailAndPassword(formData);
            window.localStorage.setItem("uid", res.data.uid);
            window.localStorage.setItem("session_token", res.data.token);
            window.localStorage.setItem("email", res.data.email);
            window.location.href = "/";
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-left text-muted mb-4 geb">
                            <span className="fs-48">Login</span>
                        </div>
                        {error ? (
                            <div className="alert alert-danger">
                                <AlertOctagon size="18" className="mr-2" style={{ verticalAlign: "sub" }} /> {error}
                            </div>
                        ) : null}
                        <Form role="form" onSubmit={(e) => e.preventDefault() && false}>
                            <FormGroup
                                className={error.includes("email") || error.includes("credential") || error.includes("Email") ? "mb-3 is-danger" : "mb-3"}
                            >
                                <InputGroup className="input-group-alternative ">
                                    <InputGroupText>
                                        <Mail size="18" />
                                    </InputGroupText>
                                    <Input
                                        className="nn "
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className={error.includes("password") || error.includes("credential") ? " is-danger" : ""}>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText>
                                        <Lock size="18" />
                                    </InputGroupText>
                                    <Input
                                        className="nn"
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <div className="text-left">
                                <Button className="my-4 nn" color="primary" type="button" onClick={login}>
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                {/* <Row className="mt-3">
					<Col xs="6"></Col>
					<Col className="text-right" xs="6">
						<span
							className="text-light cp"
							onClick={(e) => setView("REGISTER")}
						>
							<small className="gl fs-18">Create new account</small>
						</span>
					</Col>
				</Row> */}
            </Col>
        </>
    );
};
export default Login;
