import React from "react";
import { Link } from "react-router-dom";
// import { Button } from "reactstrap";

const ClientListItem = ({ index, client, darkModeFlag }) => {
	let link = `/customer/${client._id}`;

	return (
		<tr>
			<th scope="row">{index}</th>
			<th scope="row">
				<Link
					className={
						darkModeFlag === "false"
							? "text-sm text-info"
							: "text-sm text-primary"
					}
					to={link}
				>
					{client.clientFirm}
				</Link>
			</th>

			<td>{client.clientName}</td>
			<td>{client.clientPhone}</td>
		</tr>
	);
};

export default ClientListItem;
