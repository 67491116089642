import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
    family: "OSM",
    src: require("../font/OSM.ttf").default,
});

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderTopColor: "#5f5f5f",
        borderBottomColor: "#5f5f5f",
        borderBottomWidth: 1,
        borderTopWidth: 1,
        fontSize: "7pt",
        alignItems: "center",
        height: 24,
        // fontStyle: "bold",
        flexGrow: 1,
    },
    description: {
        width: "40%",
        paddingLeft: 4,
        fontFamily: "OSM",
    },
    srno: {
        width: "5%",
        fontFamily: "OSM",
    },
    date: {
        width: "10%",
        fontFamily: "OSM",
    },
    qty: {
        width: "10%",
        paddingLeft: 10,
        fontFamily: "OSM",
    },
    rate: {
        textAlign: "center",
        width: "15%",
        fontFamily: "OSM",
    },
    amount: {
        textAlign: "center",
        width: "25%",
        paddingLeft: "5%",
        fontFamily: "OSM",
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.date}>SR</Text>
        <Text style={styles.description}>DESCRIPTION</Text>
        <Text style={styles.qty}>QUANTITY (sq.feet)</Text>
        <Text style={styles.rate}>RATE</Text>
        <Text style={styles.amount}>AMOUNT</Text>
    </View>
);

export default InvoiceTableHeader;
