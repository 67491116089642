import React from "react";
import { Button, Badge } from "reactstrap";
import { getDate } from "../../../Common/DateAndTime/getDate";
import { AlertCircle, Check, Edit, Trash2 } from "react-feather";
import { FiMoreVertical } from "react-icons/fi";
import { RoundOff } from "../../../Common/DateAndTime/RoundOff";
const ListItem = ({ moreMenu, setMoreMenu, ...props }) => {
    let { entry, tax, onTapDeleteEntry, onTapEdit, onSelectEntryListner, filterFlag, idx } = props;

    return (
        <tr className="pp-l">
            {filterFlag && (
                <th scope="col">
                    <input type="checkbox" checked={entry.checked} onChange={(evt) => onSelectEntryListner(entry._id)} />
                </th>
            )}
            <th scope="row">{getDate(entry.date)}</th>
            <th scope="row">
                {entry.material}{" "}
                {entry.has_issued ? (
                    <Badge size="sm" color="success">
                        <Check size="12" style={{ verticalAlign: "bottom" }} />
                    </Badge>
                ) : (
                    <Badge size="sm" color="warning">
                        Pending
                    </Badge>
                )}
            </th>
            <th scope="row">{entry.description}</th>
            <th scope="row">{entry.qty}</th>
            <th scope="row">{entry.rate}</th>
            <th scope="row">&#8377;{RoundOff(entry.amount)}</th>
            <th scope="row">&#8377;{RoundOff(entry.total)}</th>
            <th scope="row">
                &#8377;{RoundOff(entry.advance)}{" "}
                {entry.total === 0 ? (
                    <Badge size="sm" color="success">
                        Paid
                    </Badge>
                ) : (
                    <Badge size="sm" color="warning">
                        <AlertCircle size="14" style={{ verticalAlign: "bottom" }} />
                    </Badge>
                )}
            </th>
            <th scope="row" className="text-left">
                {/* <Button className="btn btn-warning btn-sm" onClick={(e) => onTapEdit(entry._id)}>
                              <Edit size="12" />
                        </Button>
                        <Button size="sm" color="danger" onClick={(e) => onTapDeleteEntry(entry._id)}>
                              <Trash2 size="12" />
                        </Button> */}
                <div class="dropdown">
                    <Button
                        size="sm"
                        class="btn btn-sm btn-icon-only "
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => {
                            if (moreMenu === idx) {
                                setMoreMenu(null);
                            } else {
                                setMoreMenu(idx);
                            }
                        }}
                        style={{ padding: 5 }}
                    >
                        <FiMoreVertical className="text-primary" style={{ fontSize: 18 }} />
                    </Button>
                    <div
                        class={
                            moreMenu === idx
                                ? "dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                                : "dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        }
                    >
                        <span
                            onClick={() => {
                                onTapEdit(entry._id);
                                if (moreMenu === idx) {
                                    setMoreMenu(null);
                                } else {
                                    setMoreMenu(idx);
                                }
                            }}
                            class="dropdown-item text-warning"
                            href="#"
                        >
                            <Edit size="16" /> <span>Edit</span>
                        </span>
                        <span
                            class="dropdown-item text-danger"
                            onClick={() => {
                                onTapDeleteEntry(entry._id);
                                if (moreMenu === idx) {
                                    setMoreMenu(null);
                                } else {
                                    setMoreMenu(idx);
                                }
                            }}
                        >
                            <Trash2 size="16" />
                            <span>Delete</span>
                        </span>
                    </div>
                </div>
            </th>
        </tr>
    );
};

export default ListItem;
