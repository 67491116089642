import React, { useState, useEffect, useCallback } from "react";
import Footer from "../../../Common/Footers/AdminFooter";
import { Container } from "reactstrap";
import LiteHeader from "../../../Common/Header/LiteHeader";
import { sheetsBackend } from "../sheet_backend";
import { getDate, getDateForEntry } from "../../../Common/DateAndTime/getDate";
import SheetComponentPage from "./SheetComponentPage";
const PreSheetComponents = (props) => {
      const [sheetId] = useState(props.match.params.id);
      const [sheet, setSheet] = useState({});
      const [loading, setLoading] = useState(false);
      const [customers, setCustomers] = useState([]);
      const [state, setState] = useState({
            totalDue: 0,
            totalReceive: 0,
      });
      const getInfo = useCallback(async () => {
            try {
                  const formData = new FormData();
                  formData.set("uid", window.localStorage.getItem("uid"));
                  formData.set("sid", sheetId);
                  const res = await sheetsBackend.getSheetDetail(formData, window.localStorage.getItem("session_token"));
                  let totalDue = 0;
                  let totalReceive = 0;
                  for (let i = 0; i < res.data.length; i++) {
                        const customerEntries = res.data[i].entries;
                        for (let j = 0; j < customerEntries.length; j++) {
                              const entry = customerEntries[j];
                              totalDue += Number(entry.total);
                              totalReceive += Number(entry.advance);
                        }
                  }
                  setState({ totalDue: totalDue, totalReceive: totalReceive });
                  setCustomers(res.data);
                  setSheet(res);
                  setLoading(true);
            } catch (error) {
                  console.log(error);
                  setLoading(false);
            }
      }, [sheetId]);

      useEffect(() => {
            getInfo();
      }, [getInfo]);

      return loading ? (
            <>
                  <LiteHeader bg="primary" />
                  <Container className={"bg-gradient-primary"} fluid>
                        <div className="d-flex flex-row" style={{ marginTop: -15 + "rem" }}>
                              <div style={{ position: "relative" }}>
                                    <h1 className="text-white text-weight-bold geb" style={{ fontSize: "30px" }}>
                                          Date: {getDate(sheet.date)}
                                    </h1>
                              </div>
                              <div className="ml-4" style={{ position: "relative" }}>
                                    <h1 className="text-white text-weight-bold geb" style={{ fontSize: "30px" }}>
                                          Due: {state.totalDue}
                                    </h1>
                              </div>
                              <div className="ml-4" style={{ position: "relative" }}>
                                    <h1 className="text-white text-weight-bold geb" style={{ fontSize: "30px" }}>
                                          Receive: {state.totalReceive}
                                    </h1>
                              </div>
                        </div>
                        {customers.map((el, index) => (
                              <SheetComponentPage
                                    client={el}
                                    key={index}
                                    sid={sheetId}
                                    date={getDateForEntry(sheet.date)}
                                    entry={el.entries}
                                    setCustomers={setCustomers}
                                    customers={customers}
                                    uid={window.localStorage.getItem("uid")}
                              />
                        ))}
                  </Container>

                  <Container className={"bg-gradient-primary"} fluid>
                        <Footer />
                  </Container>
            </>
      ) : (
            <h1 className="text-white">Loading</h1>
      );
};

export default PreSheetComponents;
