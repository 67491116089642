import Index from "./Views/Index";
import RandomInvoice from "./Views/Invoice/Component/RandomInvoice";
// import Client from "./Views/Client/component/Client";
import { Home, Package } from "react-feather";
var routes = [
	{
		path: "/index",
		name: "Dashboard",
		icon: Home,
		component: Index,
		layout: "/admin",
	},
	{
		path: "/saved/invoice",
		name: "Invoices",
		icon: Package,
		component: RandomInvoice,
		layout: "/admin",
	},
];

export default routes;
