import React from "react";
import { ModalBody, Col, Modal, ModalHeader, Row, ModalFooter, Button } from "reactstrap";

const ConfirmModal = ({ onCancelHandler, onSubmitHandler, modal }) => {
      return (
            <Modal isOpen={modal} toggle={onCancelHandler} style={{ zIndex: 10000000000 }}>
                  <ModalHeader className="bg " toggle={onCancelHandler}>
                        <h3 className="text fs-24 geb">Save this invoice</h3>
                  </ModalHeader>
                  <ModalBody className="bg">
                        <Row>
                              <Col>
                                    <h4 className="text geb">Are you sure?</h4>
                              </Col>
                        </Row>
                  </ModalBody>
                  <ModalFooter className="bg">
                        <Button color="primary" className="btn fira btn-success" onClick={onSubmitHandler}>
                              Done
                        </Button>{" "}
                        <Button color="secondary" className="btn fira btn-warning" onClick={onCancelHandler}>
                              Cancel
                        </Button>
                  </ModalFooter>
            </Modal>
      );
};

export default ConfirmModal;
